import axios from "axios";
import React from "react";

export const TemplateEmail = async (email, password) => {
  let body = {
    // api_key: smtp2goKey,
    api_key: "api-ADA747C221F411ED9228F23C91BBF4A0",
    to: ["<" + email + ">"],
    cc: [],
    bcc: [],
    sender: "Advertising Association <info@alcottgrp.com>",
    subject: "New Employee | Advertising Association",
    text_body: "Advertising Association",
    html_body:
      `
      <div class="es-wrapper-color" style="background-color: #fafafa">
      <table
        class="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        style="
          border-collapse: collapse;
          border-spacing: 0px;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-repeat: repeat;
          background-position: center top;
        "
      >
        <tr style="border-collapse: collapse">
          <td valign="top" style="padding-top: 5vh; margin: 0">
            <table
              cellpadding="0"
              cellspacing="0"
              class="es-header"
              align="center"
              style="
                border-collapse: collapse;
                border-spacing: 0px;
                table-layout: fixed !important;
                width: 100%;
                background-color: transparent;
                background-repeat: repeat;
                background-position: center top;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding: 0; margin: 0">
                  <table
                    class="es-header-body"
                    cellspacing="0"
                    cellpadding="0"
                    align="center"
                    style="
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #e6ebef;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        bgcolor="#ffffff"
                        style="padding: 0; margin: 0; background-color: #ffffff"
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          align="left"
                          class="es-left"
                          style="
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: left;
                          "
                        >
                          <tr style="border-collapse: collapse">
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                style="
                                float:left;
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    class="es-m-txt-l"
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-right: 20px;
                                      padding-top: 25px;
                                      padding-left: 40px;
                                      font-size: 0px;
                                    "
                                  >
                                    <img
                                      src="https://res.cloudinary.com/alcottimg/image/upload/v1665654508/Advertising%20Association/Icon%20Employee/aalogotransparant_bf6pnp.png"
                                      alt
                                      style="
                                        display: block;
                                        border: 0;
                                        outline: none;
                                        text-decoration: none;
                                        -ms-interpolation-mode: bicubic;
                                      "
                                      width="190"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td> 
                          </tr>
                        </table>
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="es-right"
                          align="right"
                          style="
                            border-collapse: collapse;
                            border-spacing: 0px;
                            float: right;
                          "
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              align="left"
                              style="padding: 0; margin: 0; width: 42px"
                            >
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                style="
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="center"
                                    style="padding: 0; margin: 0; display: none"
                                  ></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              class="es-content"
              cellspacing="0"
              cellpadding="0"
              align="center"
              style="
                border-collapse: collapse;
                border-spacing: 0px;
                table-layout: fixed !important;
                width: 100%;
              "
            >
              <tr style="border-collapse: collapse">
                <td align="center" style="padding-bottom: 5vh; margin: 0">
                  <table
                    class="es-content-body"
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#ffffff"
                    align="center"
                    style="
                      border-collapse: collapse;
                      border-spacing: 0px;
                      background-color: #ffffff;
                      width: 600px;
                    "
                  >
                    <tr style="border-collapse: collapse">
                      <td
                        align="left"
                        style="
                          margin: 0;
                          padding-top: 15px;
                          padding-left: 30px;
                          padding-right: 30px;
                          padding-bottom: 40px;
                        "
                      >
                        <table
                          width="100%"
                          cellspacing="0"
                          cellpadding="0"
                          style="border-collapse: collapse; border-spacing: 0px"
                        >
                          <tr style="border-collapse: collapse">
                            <td
                              valign="top"
                              align="center"
                              style="padding: 0; margin: 0; width: 540px"
                            >
                              <table
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                style="
                                  border-collapse: collapse;
                                  border-spacing: 0px;
                                "
                              >
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                       padding-top: 8px;
                                      margin: 0;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <h3
                                      style="
                                        margin: 0;
                                        line-height: 24px;
                                        font-family: arial, 'helvetica neue', helvetica, sans-serif;
                                        font-size: 20px;
                                        font-style: normal;
                                        font-weight: normal;
                                        color: #333333;
                                      "
                                    >
                                      New Employee
                                    </h3>
                                  </td>
                                </tr>

                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 15px;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 28px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                    Welcome to the Advertising Association app.
                                    </p>
                                  </td>
                                </tr>

                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 15px;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 28px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                      Please download the app to your phone from the App Store or Google Play Store. 
                                    </p>
                                  </td>
                                </tr>

                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 15px;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                  You can login using the details below:<br />
                                    </p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 15px;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                    Username : ` +
      email +
      ` <br/>
                                    Password : ` +
      password +
      `
                                    </p>
                                  </td>
                                </tr>

                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-top: 15px;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                       *Please change your password the first time you login.
                                    </p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      padding: 0;
                                      margin: 0;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                      padding-top: 20px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                     Best Regards,
                                    </p>
                                  </td>
                                </tr>
                                <tr style="border-collapse: collapse">
                                  <td
                                    align="left"
                                    style="
                                      margin: 0;
                                      padding-left: 15px;
                                      padding-right: 15px;
                                      padding-top: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    <p
                                      style="
                                        margin: 0;
                                        -webkit-text-size-adjust: none;
                                        -ms-text-size-adjust: none;
                                        font-family: arial, 'helvetica neue',
                                          helvetica, sans-serif;
                                        line-height: 21px;
                                        color: #353537;
                                        font-size: 14px;
                                      "
                                    >
                                      Advertising Association Team
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
      `,
  };

  await axios
    .post("https://api.smtp2go.com/v3/email/send", body)
    .then((response) => {
      if (response) {
        //window.location.reload(true)
      }
    })
    .catch((err) => {});
};

export default TemplateEmail;
