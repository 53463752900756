import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
function LoginPage() {
  const cookies = new Cookies();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState([]);

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    await axios
      .post("auth/login?email=" + email + "&password=" + password)
      .then((response) => {
        var remember = document.getElementById("rememberMe");
        remember.checked == true
          ? cookies.set("token", response.data.access_token, {
              maxAge: 2592000,
            })
          : cookies.set("token", response.data.access_token);
        remember.checked == true
          ? cookies.set("expiredToken", response.data.expires_in, {
              maxAge: 2592000,
            })
          : cookies.set("expiredToken", response.data.expires_in);
        navigate("/dashboard-page");
      })
      .catch((error) => {
        setValidation(error.response.data);
      });
  };
  useEffect(() => {
    if (cookies.get("token")) {
      axios
        .post("auth/me?token=" + cookies.get("token"))
        .then((response) => {
          navigate("/dashboard-page");
        })
        .catch((error) => {
          cookies.remove("token");
          cookies.remove("expiredToken");
        });
    }
  }, []);
  return (
    <div
      className="LoginContainer"
      style={{
        background: "#F9F9F9",
      }}
    >
      <div className="LoginField">
        <div className="LoginImg">
          <img
            className="imgL"
            src="https://res.cloudinary.com/alcottimg/image/upload/v1665654508/Advertising%20Association/Icon%20Employee/aalogotransparant_bf6pnp.png"
          ></img>
        </div>

        <div className="LoginIntro">
          <span className="welcome-title">Welcome Back!</span>
          <div className="LoginInput">
            <Form name="normal_login" className="login-form">
              <div style={{ textAlign: "left", paddingBottom: "20px" }}>
                <label
                  style={{
                    color: "#A3A3A3",
                    fontWeight: "500",
                    opacity: 1,
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  Email
                </label>
                <Input
                  id="input-username-login"
                  placeholder="Email"
                  className="input-login-username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                {(validation.email && (
                  <div style={{ color: "red" }}>{validation.email[0]}</div>
                )) ||
                  (validation.error && (
                    <div style={{ color: "red" }}>{validation.error}</div>
                  ))}
              </div>

              <div
                style={{ textAlign: "left", paddingBottom: "20px" }}
                className="mb-3"
              >
                <label
                  style={{
                    color: "#A3A3A3",
                    fontWeight: "500",
                    opacity: 1,
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  Password
                </label>
                <Input.Password
                  id="input-password-login"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {(validation.password && (
                  <div style={{ color: "red" }}>{validation.password[0]}</div>
                )) ||
                  (validation.error && (
                    <div style={{ color: "red" }}>{validation.error}</div>
                  ))}
              </div>
              <Form.Item>
                {/* <Link to="/dashboard-page"> */}
                <div id="remember-me-2 " className="d-flex mb-3">
                  {/* <input  type="checkbox" />
  <label className="ms-2">Remember Me</label> */}

                  <label class="container2">
                    Remember Me
                    <input
                      id="rememberMe"
                      type="checkbox"
                      //  checked="checked"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  onClick={login}
                >
                  Log in
                </Button>
                {/* </Link> */}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
