import { Table, Input, Button, Spin } from "antd";
import { FaSearch } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
const dateFormat = "DD/MM/YYYY";
const columns = [
  {
    title: "Employee",
    key: "user_timesheet_id",
    dataIndex: "full_name",
    width: 200,
    sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    sortDirections: ["descend", "ascend"],
    key: "full_name",
  },
  {
    title: "Team",
    key: "user_timesheet_id",
    dataIndex: "team_name",
    width: 200,
    sorter: (a, b) => a.team_name.localeCompare(b.team_name),
    sortDirections: ["descend", "ascend"],
    key: "team_name",
  },
  {
    title: "Week Commencing",
    key: "user_timesheet_id",
    dataIndex: "start_date",
    width: 200,
    render: (start_date) => moment(start_date).format(dateFormat),
    sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix(),
    key: "start_date",
  },
  {
    title: "Month",
    key: "user_timesheet_id",
    dataIndex: "title",
    width: 170,
    sorter: (a, b) => a.title_num - b.title_num,
    sortDirections: ["descend", "ascend"],
    key: "title",
  },

  {
    title: "Days Remaining",
    key: "user_timesheet_id",
    dataIndex: "remaining",
    width: 180,
    sorter: (a, b) => a.remaining - b.remaining,
    sortDirections: ["descend", "ascend"],
    key: "remaining",
  },

  {
    title: "Overdue",
    key: "user_timesheet_id",
    dataIndex: "overdue_value",
    render: (_, { overdue_value }) => (
      <>
        {(overdue_value === 1 && (
          <label className="text-status-overdue">Yes</label>
        )) ||
          (overdue_value === 0 && <label>No</label>)}
        {/* dikasih kondisi disini sesuai status Overdue nya  */}
      </>
    ),
    sorter: (a, b) => a.overdue_value - b.overdue_value,
    sortDirections: ["descend", "ascend"],
    key: "overdue_value",
  },

  {
    title: "Status",
    key: "user_timesheet_id",
    dataIndex: "status_id",
    render: (_, { status_id }) => (
      <>
        {/* dikasih kondisi disini sesuai statusnya  */}
        {(status_id === 1 && (
          <label className="text-status-open">Open</label>
        )) ||
          (status_id === 2 && (
            <label className="text-status-inprogress">In Progress</label>
          )) ||
          (status_id === 3 && (
            <label className="text-awaiting-approval">Awaiting Approval</label>
          )) ||
          (status_id === 4 && (
            <label className="text-approval">Approved</label>
          ))}

        {/* <label className="text-status-overdue">
              Overdue
            </label>
            <label className="text-status-inprogress">
              In Progress
            </label>
            <label className="text-status-awaiting-approval">
              Awaiting Approval
            </label> */}
      </>
    ),
    sorter: (a, b) => a.status_id - b.status_id,
    sortDirections: ["descend", "ascend"],
    key: "status_id",
  },

  {
    title: "Submitted",
    key: "user_timesheet_id",
    dataIndex: "submit_date",
    // sorter: (a, b) => ( moment(a.submit_date).unix() - moment(b.submit_date).unix() ) ,
    render: (submit_date) =>
      submit_date != "Not submitted yet"
        ? moment(submit_date).format(dateFormat)
        : submit_date,
    sorter: (a, b) => {
      if (
        a.submit_date == "Not submitted yet" ||
        b.submit_date == "Not submitted yet"
      ) {
        return a.submit_date.length - b.submit_date.length;
      }
      return moment(a.submit_date).unix() - moment(b.submit_date).unix();
    },
  },
];

const TableDashboard = (props) => {
  const location = useLocation();
  const [originData, setOriginData] = useState([]);

  const [datas, setDatas] = useState([]);
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const searchData = () => {
    if (search !== "") {
      setSearch(search);
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      );
      setDatas([...dataSearch]);
    } else {
      setSearch(search);
      setDatas(originData);
    }
  };

  const clearFilter = () => {
    setSearch("");
    setDatas(originData);
  };

  useEffect(() => {
    setDatas(props.datas);
    setOriginData(props.originData);
  }, [props]);

  let navigate = useNavigate();

  function handleNavigate(id) {
    navigate("/dashboard-page/details", {
      state: { id: id, menu: "dashboardPage" },
    });
  }
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4 justify-content-between">
        <div className=" text-start">
          <label style={{ fontSize: "14px" }}>Records : {datas.length}</label>
        </div>
        <div className=" justify-end d-flex ">
          <Input
            style={{ fontSize: "14px", width: "100%" }}
            className="input-aa pe-2"
            placeholder="Search"
            onChange={handleChange}
            value={search}
          />
          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={searchData}
          >
            <FaSearch onClick={searchData} />
          </Button>

          <Button htmlType="submit" className="btn-aa" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>
      {(props.loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (props.loading === false && (
          <>
            <Table
              columns={columns}
              dataSource={datas}
              pagination={{
                pageSizeOptions: ["50", "100"],
                showSizeChanger: true,
                defaultPageSize: 50,
                itemRender: itemRender,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleNavigate(record.user_timesheet_id);
                  }, // click row
                };
              }}
            />
          </>
        ))}
    </div>
  );
};

export default TableDashboard;
