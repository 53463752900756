import { Button, Input, Table, DatePicker, Select, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const cookies = new Cookies();

const token = cookies.get("token");

const dateFormatList = "DD/MM/YYYY";

const { Option } = Select;

const TableNewTimesheet = () => {
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  const columns = [
    {
      title: "",
      dataIndex: "action",
      render: (_, record) => (
        <div className="">
          <div className="icon-solid-danger">
            <BsFillTrashFill />
          </div>
          <div
            className="icon-editable-danger"
            onClick={() => deleteNewTimesheet(record)}
          ></div>
        </div>
      ),
    },
    {
      title: "Month",
      dataIndex: "title",
    },
    {
      title: "Start Date Timesheet",
      dataIndex: "start_date",
      render: (submit_date) => moment(submit_date).format(dateFormat),
    },

    {
      title: "End Date Timesheet",
      dataIndex: "finish_date",
      render: (finish_date) => moment(finish_date).format(dateFormat),
    },

    {
      title: "Date Created",
      dataIndex: "date_created",
      render: (date_created) => moment(date_created).format(dateFormat),
    },

    {
      title: "Show",
      dataIndex: "show",
    },
  ];

  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [userId, setUserId] = useState([]);
  const [type, setType] = useState("Show");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorFinishDate, setErrorFinishDate] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledSendButton, setDisabledSendButton] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setloading] = useState(false);

  const fetchData = async () => {
    setloading(true);
    axios
      .get("newtimesheet")
      .then((response) => {
        if (response) {
          // setData();
          setDatas(response.data);
          setOriginData(response.data);
          setloading(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchData();
    // return () => {
    //   second
    // }
  }, []);

  const deleteNewTimesheet = (record) => {
    setUserId(record.timesheet_id);
    setModalDeleteItem(true);
  };
  const deleteFunction = () => {
    setDisabledButton(true);
    axios
      .post("newtimesheet/" + userId)
      .then((response) => {
        // setDisabledButton(true)
        window.location.reload();
      })
      .catch((error) => {});
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const searchData = () => {
    if (search !== "") {
      setSearch(search);
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      );
      setDatas([...dataSearch]);
    } else {
      setSearch(search);
      setDatas(originData);
    }
  };
  const clearFilter = () => {
    setType("Show");
    setStartDate("");
    setFinishDate("");
  };
  const clearFilterSearch = () => {
    setSearch("");
    setDatas(originData);
  };
  useEffect(() => {
    setErrorStartDate("");
    setErrorFinishDate("");
  }, [startDate, finishDate]);
  const sendHanlder = () => {
    setDisabledButton(true);
    if (!startDate) {
      setErrorStartDate("Value is required!");
      setDisabledButton(false);
    }
    if (!finishDate) {
      setErrorFinishDate("Value is required!");
      setDisabledButton(false);
    }
    axios
      .post(
        "newtimesheet?start_date=" +
          moment(startDate).format(dateFormatList) +
          "&finish_date=" +
          moment(finishDate).format(dateFormatList) +
          "&show=" +
          type
      )
      .then((response) => {
        if (response) {
          setDisabledButton(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        // setValidation(error.response.data);
      });
  };
  const onChangeStart = (date, dateString) => {
    if (dateString == "") {
      setStartDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setStartDate(formatDate);
    }
  };
  const onChangeFinish = (date, dateString) => {
    if (dateString == "") {
      setFinishDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setFinishDate(formatDate);
    }
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  useEffect(() => {
    if (!startDate && !finishDate) {
      setDisabledSendButton(true);
    } else if (startDate && !finishDate) {
      setDisabledSendButton(true);
    } else if (!startDate && finishDate) {
      setDisabledSendButton(true);
    } else if (startDate > finishDate || finishDate < startDate) {
      setDisabledSendButton(true);
    } else {
      setDisabledSendButton(false);
    }
  }, [finishDate, startDate]);
  return (
    <div className="px-3 table-approve-page">
      <div className="col-12 text-dark-ds pb-3">
        <b>Set Timesheet on Weekly</b>
      </div>
      <div className="d-flex pb-3  justify-content-between">
        <div className="  text-start d-flex">
          <DatePicker
            placeholder="Start Date"
            className="width-filter"
            value={startDate !== "" ? moment(startDate, "YYYY-MM-DD") : null}
            format={dateFormat}
            onChange={onChangeStart}
          />
          <label className=" text-dark-ds mx-2 py-1">Until</label>
          <DatePicker
            onChange={onChangeFinish}
            placeholder="End Date"
            className="width-filter"
            value={finishDate !== "" ? moment(finishDate) : null}
            format={dateFormat}
          />

          <Input.Group compact className="ms-2 me-2">
            <Select value={type} onChange={(value) => setType(value)}>
              <Option value="Show" style={{ paddingLeft: "10px" }}>
                Show
              </Option>
              <Option value="Hide">Hide</Option>
            </Select>
          </Input.Group>

          <Button
            className="btn-aa px-3  me-2"
            onClick={sendHanlder}
            disabled={disabledSendButton}
          >
            Send
          </Button>

          <Button className="btn-aa px-3 me-2" onClick={clearFilter}>
            Clear
          </Button>
        </div>
        <div className="d-flex">
          <Input
            className="input-aa  pe-2"
            placeholder="Search"
            value={search}
            onChange={handleChange}
          />
          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={searchData}
          >
            <FaSearch onClick={searchData} />
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa"
            onClick={clearFilterSearch}
          >
            Clear
          </Button>
        </div>
      </div>

      <div className="col-12 text-secondary-ds pb-3">
        <label>The number of days before a timesheet becomes overdue</label>
      </div>

      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading == false && (
          <Table
            columns={columns}
            dataSource={datas}
            pagination={{
              pageSizeOptions: ["50", "100"],
              showSizeChanger: true,
              defaultPageSize: 50,
              itemRender: itemRender,
            }}
          />
        ))}

      {/* MODAL DELETE ITEM*/}
      <Modal
        title={null}
        centered
        visible={modalDeleteItem}
        onOk={() => setModalDeleteItem(false)}
        onCancel={() => setModalDeleteItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Delete Item</b>
        </div>

        <label className="text-dark-ds pb-2 mb-3 ">
          Are you sure want to delete this record?{" "}
        </label>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={deleteFunction}
            disabled={disabledButton}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalDeleteItem(false)}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableNewTimesheet;
