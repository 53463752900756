import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import DashboardPage from "./pages/dashboard/DashboardPage";
import LoginPage from "./pages/login/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"
import ApprovePage from "./pages/approve/ApprovePage";
import NewTimesheetPage from "./pages/new-timesheet/NewTimesheetPage";
import UnopenedTimesheetPage from "./pages/unopened-timesheet/UnopenedTimesheetPage";
import ViewAllPage from "./pages/view-all/ViewAllPage";
import EntitiesPage from "./pages/entities/EntitiesPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import TeamPage from "./pages/teams/TeamPage";
import EmployeesPage from "./pages/employees/EmployeesPage";
import SettingsPage from "./pages/settings/SettingsPage";
import CostPerEntities from "./pages/cost-per-entitie/CostPerEntities";
import DetaileEmployee from "./pages/dashboard/detail/DetaileEmployee";
import ApproveDetail from "./pages/approve/detail/ApproveDetail";
import ViewAllDetail from "./pages/view-all/detail/ViewAllDetail";



function App() {
  return (
    <Routes>
        <Route exact  path="/" element={< LoginPage/>} />
        <Route path="/" element={<Sidebar />}>
        <Route exact path="dashboard-page" element={<DashboardPage />} />
        <Route exact path="dashboard-page/details" element={<DetaileEmployee />} />
        <Route exact path="approve-page" element={<ApprovePage />} />
        <Route exact path="approve-page/details" element={<ApproveDetail />} />
        <Route exact path="newtimesheet-page" element={<NewTimesheetPage />} />
        <Route exact path="unopened-timesheet-page" element={<UnopenedTimesheetPage />} />
        <Route exact path="view-all-page" element={<ViewAllPage />} />
        <Route exact path="view-all-page/details" element={<ViewAllDetail />} />
        <Route exact path="entities-page" element={<EntitiesPage />} />
        <Route exact path="projects-page" element={<ProjectsPage />} />
        <Route exact path="teams-page" element={<TeamPage/>} />
        <Route exact path="employees-page" element={<EmployeesPage/>} />
        <Route exact path="settings-page" element={<SettingsPage/>} />
        <Route exact path="cost-per-entities-page" element={<CostPerEntities/>} />
        

      </Route>
    </Routes>
  );
}

export default App;
