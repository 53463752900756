import React from "react";
import TableDetailStatusEmployee from "../../../components/table/detail/TableDetailStatusEmployee";

const DetaileEmployee = () => {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
        <div className="pt-2 title-table-ds">
          <p>
            <b>Timesheet Info</b>
          </p>
        </div>
        {/* Table */}
        <div className="card-table-ds">
          <TableDetailStatusEmployee />
        </div>
      </div>
    </div>
  );
};

export default DetaileEmployee;
