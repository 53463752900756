import React from 'react'
import TableTeams from '../../components/table/TableTeams'
import "./TeamsPage.css"

const TeamPage = () => {
  return (
    <div className="right-side-wrapper">
    <div className="customePaddingContainer">
    <div className="pt-2 title-table-ds">
        <p>
          <b>Teams</b>
        </p>
      </div>
      <div className="card-table-ds">
        <TableTeams/>
      </div>
    </div>
  </div>
  )
}

export default TeamPage