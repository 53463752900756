import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import TableDashboard from "../../components/table/TableDashboard";
import TableRows from "../../components/table/tablerows";
import "./Dashboard.css";
import Cookies from "universal-cookie";
import { Skeleton, Space, Spin } from "antd";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
function DashboardPage() {
  const cookies = new Cookies();
  const [user, setUser] = useState("");
  const [status, setStatus] = useState({});
  const [gif, setGif] = useState("");
  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const token = cookies.get("token");
  const expiredIn = cookies.get("expiredToken");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const numberMonth = (data) => {
    const month = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    for (var i = 0; i < Object.keys(month).length; i++) {
      if (data == Object.keys(month)[i]) {
        return Object.values(month)[i];
      }
    }
  };

  const navigate = useNavigate();
  const fetchData = async () => {
    setLoading(true);
    axios
      .post("auth/me?token=" + token)
      .then((result) => {
        if (result) {
          setUser(result.data.full_name);
        }
      })
      .catch((err) => {});

    axios
      .get("card/status")
      .then((res) => {
        if (res) {
          setStatus(res.data);
        }
      })
      .catch((err) => {});

    axios
      .get("dashboard")
      .then((res) => {
        if (res) {
          var datas = [];
          res.data.map((data) => {
            data.title_num = numberMonth(data.title);
            datas.push(data);
          });
          setDatas(datas);
          setOriginData(datas);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const timeout = () => {
    setTimeout(() => {
      localStorage.clear();
    }, expiredIn);
  };

  const randomGif = async () => {
    setLoading(true);
    setActive(true);
    axios
      .get("gif/random")
      .then((res) => {
        if (res) {
          setGif(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    timeout();
    if (!token) {
      navigate("/");
    }
    fetchData();
    randomGif();
  }, []);

  const statusFilter = (val) => {
    if (val === "overdue") {
      const dataSearch = originData.filter(
        (data) => data.overdue_value === 1 && data
      );
      setDatas([...dataSearch]);
    } else {
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k]).toLocaleLowerCase().includes(val.toLocaleLowerCase())
        )
      );
      setDatas([...dataSearch]);
    }
  };

  return (
    <div className="right-side-wrapper ">
      <div className="customePaddingContainer">
        <div className="header-card-dashboard">
          <div className="d-flex">
            <div className=" col-xxl-2 col-xl-2 col-lg-3 ">
              {(loading === true && (
                <Space>
                  <Skeleton.Image
                    active={true}
                    style={{ width: "12vw", height: "150px" }}
                  />
                </Space>
              )) ||
                (loading === false && (
                  <img src={gif} className="giphy-ds" alt="" />
                ))}
            </div>
            <div className="col-9 col-xl-10 col-lg-9 mx-0 px-0 ">
              <div className="container hi-user-ds">
                <p>
                  <b>Hello,</b> {user}
                </p>
              </div>
              <div className="container d-flex p-0 m-0  ">
                <div className="col-3   mt-xl-3 mt-lg-3 mt-5 pointer ">
                  <div
                    className="card-filter-ds-warapper mx-3"
                    onClick={() => statusFilter("open")}
                  >
                    <div className="card-filter-ds-open row ">
                      <div className="col-xxl-6 ">
                        <div className="label-warapper-ds-open pointer bg-open py-xl-3 py-lg-2 py-3 ">
                          <label>{status[0]?.open}</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 my-auto">
                        <div className="py-2 py-lg-1 pb-lg-3 pt-lg-2 pe-xxl-4 text-center ">
                          <label>Open</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3  mt-xl-3 mt-lg-3 mt-5 pointer ">
                  <div
                    className="card-filter-ds-warapper mx-3"
                    onClick={() => statusFilter("overdue")}
                  >
                    <div className="card-filter-ds-open row ">
                      <div className="col-xxl-6 ">
                        <div className="label-warapper-ds-open pointer bg-overdue py-xl-3 py-lg-2 py-3 ">
                          <label>{status[0]?.overdue}</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 ps-xl-4  my-auto">
                        <div className="py-2 py-lg-1 text-center pe-xxl-4 pb-lg-3 pt-lg-2  ">
                          <label>Overdue</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3  mt-xl-3 mt-lg-3 mt-5 pointer ">
                  <div
                    className="card-filter-ds-warapper mx-3"
                    onClick={() => statusFilter("in progress")}
                  >
                    <div className="card-filter-ds-open row ">
                      <div className="col-xxl-6 ">
                        <div className="label-warapper-ds-open pointer bg-inprogress py-xl-3 py-lg-2 py-3 ">
                          <label>{status[0]?.in_progress}</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 ps-xl-4  my-auto">
                        <div className="py-2 py-lg-1 text-center pe-xxl-4 pe-lg-0  pb-lg-3 pt-lg-2 ">
                          <label className="">In Progress</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3  mt-xl-3 mt-lg-3 mt-5 pointer ">
                  <div
                    className="card-filter-ds-warapper mx-3"
                    onClick={() => statusFilter("awaiting approval")}
                  >
                    <div className="card-filter-ds-open row ">
                      <div className="col-xxl-6 ">
                        <div className="label-warapper-ds-open pointer bg-wait-approve py-xl-3 py-lg-2 py-3 ">
                          <label>{status[0]?.waiting_approval}</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 ps-xl-4  my-auto">
                        <div className=" pb-lg-3 pt-lg-2 py-xxl-0  text-center pe-xxl-4 pe-lg-1">
                          <label>Awaiting Approval</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-4 title-table-ds">
          <p>
            <b>Outstanding</b>
          </p>
        </div>
        {/* Table */}

        <div className="card-table-ds">
          <TableDashboard
            datas={datas}
            originData={originData}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
