import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./table.css";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Button,
  Pagination,
  Modal,
  Spin,
} from "antd";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";
import { FaCheck, FaPen, FaSearch } from "react-icons/fa";
import axios from "axios";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }

  if (type === "next") {
    return <a>Next</a>;
  }

  return originalElement;
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <Input /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableTeams = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [originData, setoriginData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [condition, setCondition] = useState("");
  const [disabledSave, setdisabledSave] = useState(false);
  const [disableAdd, setdisableAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minus, setminus] = useState("minus-top");

  const fetchData = () => {
    setLoading(true);
    setdisableAdd(true);
    axios
      .get("teams")
      .then((response) => {
        setData(response.data);
        setoriginData(response.data);
        setCount(response.data.length - 1);
        setdisableAdd(false);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  // tambah row
  const newestData = data.length - 1;
  const [count, setCount] = useState(newestData);
  const handleAdd = () => {
    const newData = {
      key: count + 1,
    };
    setData([...data, newData]);
    setCount(count + 1);
    setEditingKey(newData.key);
    setCondition("add");
    form.resetFields();
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const searchData = () => {
    if (search !== "") {
      setSearch(search);
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      );
      if (!dataSearch.length) {
        setminus("");
      } else {
        setminus("minus-top");
      }
      setData([...dataSearch]);
    } else {
      setminus("minus-top");
      setSearch(search);
      setData(originData);
    }
  };

  const clearFilter = () => {
    setminus("minus-top");
    setSearch("");
    setData(originData);
  };

  useEffect(() => {
    axios
      .get("teams")
      .then((response) => {
        setoriginData(response.data);
      })
      .catch((error) => {});
  }, [data]);

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      team_name: "",
      ...record,
    });
    setEditingKey(record.key);
    setCondition("");
  };

  const cancel = () => {
    // const newData = [...data];
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      setdisabledSave(true);
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        axios
          .post("teams?team_name=" + row.team_name)
          .then((response) => {
            setData(newData);
            setEditingKey("");
            setdisabledSave(false);
          })
          .catch((error) => {});
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      setdisabledSave(false);
    }
  };

  const saveEdit = async (key, record) => {
    try {
      setdisabledSave(true);
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (!record.team_id) {
        var lastData = originData[originData.length - 1];
        var teamId = lastData.team_id;
      } else {
        var teamId = record.team_id;
      }
      if (index > -1) {
        axios
          .post("teams/" + teamId + "?team_name=" + row.team_name)
          .then((response) => {
            setEditingKey("");
            setdisabledSave(false);
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setData(newData);
          })
          .catch((error) => {
            setdisabledSave(false);
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      setdisabledSave(false);
    }
  };

  const handleDelete = (key, id) => {
    if (!id) {
      var lastData = originData[originData.length - 1];
      id = lastData.project_id;
    } else {
      id = id;
    }
    axios
      .post("teams/delete/" + id)
      .then((response) => {})
      .catch((error) => {});
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    setEditingKey("");
  };

  const columns = [
    {
      // title: "operation",
      dataIndex: "operation",
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        if (condition == "add") {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                disabled={disabledSave}
                onClick={() => save(record.key)}
                style={{
                  marginRight: 10,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck disabled={disabledSave} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                // disabled={editingKey !== ""}
                disabled={disabledSave}
                title="Sure to cancel?"
                onConfirm={() => handleDelete(record.key)}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp disabled={disabledSave} />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>

              {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm> */}
            </span>
          ) : (
            <span className="d-flex">
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
                style={{
                  marginRight: 10,
                  paddingTop: 2,
                }}
              >
                <div className="icon-solid2">
                  <FaPen disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key, record.team_id)}
              >
                <div className="icon-solid-danger3">
                  <BsFillTrashFill disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable-danger3"></div>
              </Popconfirm>
            </span>
          );
        } else {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                disabled={disabledSave}
                onClick={() => saveEdit(record.key, record)}
                style={{
                  marginRight: 20,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck disabled={disabledSave} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              {/* <Popconfirm 
                  // disabled={editingKey !== ""}
                  title="Sure to cancel?" 
                  onConfirm={() => handleDelete(record.key)}>
                    <a >Cancel</a>
                </Popconfirm> */}

              <Popconfirm
                title="Sure to cancel?"
                onConfirm={cancel}
                disabled={disabledSave}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp disabled={disabledSave} />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>
            </span>
          ) : (
            <span style={{ display: "flex" }}>
              <div
                className="me-3"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                <div className="icon-solid2">
                  <FaPen disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable2"></div>
              </div>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key, record.team_id)}
              >
                <div className="icon-solid-danger">
                  <BsFillTrashFill disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable-danger"></div>
              </Popconfirm>
            </span>
          );
        }
      },
    },

    {
      title: "Team Name",
      dataIndex: "team_name",
      editable: true,
      // sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      // sortDirections: ["descend", "ascend"],
      // key: "team_name",
    },
  ];

  // useEffect(()=>{
  //   edit()
  // }, [data])
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4 justify-content-between">
        <div className="text-start">
          <label style={{ fontSize: "14px" }}>Records : {data.length}</label>
        </div>

        <div className="justify-end d-flex">
          <Input
            className="input-aa pe-2 "
            placeholder="Search"
            onChange={handleChange}
            value={search}
          />
          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={searchData}
          >
            <FaSearch onClick={searchData} />
          </Button>

          <Button htmlType="submit" className="btn-aa" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>

      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading === false && (
          <Form form={form} component={false}>
            <div className="table-with-button">
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered={false}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  pageSizeOptions: ["50", "100"],
                  showSizeChanger: true,
                  defaultPageSize: 50,
                  itemRender: itemRender,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="addRowBtn"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  order: 1,
                }}
              >
                <Button
                  onClick={handleAdd}
                  disabled={editingKey !== "" || disableAdd}
                  className={`${minus} btn-aa mx-0 `}
                >
                  Add New Row
                </Button>
              </div>
            </div>
          </Form>
        ))}

      {/* MODAL DELETE ITEM*/}
      <Modal
        title={null}
        centered
        visible={modalDeleteItem}
        onOk={() => setModalDeleteItem(false)}
        onCancel={() => setModalDeleteItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Delete Item</b>
        </div>

        <label className="text-dark-ds pb-2 mb-3 ">
          Are you sure want to delete this record?{" "}
        </label>

        <div className=" text-center">
          <Button htmlType="submit" className="btn-aa px-5  ">
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalDeleteItem(false)}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableTeams;
