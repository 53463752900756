import React from 'react'
import TableNewTimesheet from '../../components/table/TableNewTimesheet'
import "./NewTimesheetPage.css"

const NewTimesheetPage = () => {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
      <div className="pt-2 title-table-ds">
          <p>
            <b>New Timesheet</b>
          </p>
        </div>
        <div className="card-table-ds">
          <TableNewTimesheet/>
        </div>
      </div>
    </div>
  )
}

export default NewTimesheetPage