import TableApprove from "../../components/table/TableApprove";
import "./ApprovePage.css"

function ApprovePage() {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
      <div className="pt-2 title-table-ds">
          <p>
            <b>Approve</b>
          </p>
        </div>
        <div className="card-table-ds">
          <TableApprove/>
        </div>
      </div>
    </div>
  );
}

export default ApprovePage;

