import {
  Button,
  Input,
  Table,
  DatePicker,
  Select,
  Space,
  Pagination,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import "./table.css";
import axios from "axios";
import moment from "moment";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const { Option } = Select;

const TableNewUnOpenTimesheets = () => {
  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [teamValue, setTeamValue] = useState("");
  const [employeeName, setemployeeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [listEmployee, setlistEmployee] = useState("");
  const [listEmployeeLength, setlistEmployeeLength] = useState("");

  const [current, setCurrent] = useState(1);
  // const [current2, setCurrent2] = useState(1);
  const [minindex, setminindex] = useState(0);
  const [maxindex, setmaxindex] = useState(0);
  const [totalPage, settotalPage] = useState(0);

  const [loading, setloading] = useState(false);
  var pageSize = 2;

  const dateFormat = "DD/MM/YYYY";
  const fetchData = async () => {
    setloading(true);
    axios
      .get("unopenedtimesheet")
      .then((response) => {
        if (response) {
          // var theDatas = [];
          // response.data.map((data, key) => {
          //   theDatas.push({
          //     no: key + 1,
          //     email: data.email,
          //     finish_date: data.finish_date,
          //     start_date: data.start_date,
          //     full_name: data.full_name,
          //     id_timesheet: data.id_timesheet,
          //     team_id: data.team_id,
          //     team_name: data.team_name,
          //     timesheet_id: data.timesheet_id,
          //     user_id: data.user_id,
          //   });
          // });
          setDatas(response.data);
          setOriginData(response.data);
          // setDatas(theDatas);
          // setOriginData(theDatas);
          settotalPage(resultsss.length / pageSize);
          setminindex(0);
          setmaxindex(pageSize);
          setloading(false);
        }
      })
      .catch((err) => {});
    axios
      .get("viewall/team")
      .then((response) => {
        if (response) {
          setFilterTeam(response.data);
        }
      })
      .catch((err) => {});
  };

  const { Column, ColumnGroup } = Table;

  const onChange = (date, dateString) => {};

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  const customeHeader = (startDate, finishDate) => {
    return (
      <div className="">
        <div className="text-center">
          <label>
            Start Date{" "}
            <span className="px-3" style={{ fontSize: "13px" }}>
              {" "}
              {moment(startDate).format(dateFormat)}
            </span>{" "}
            <span className="ps-5" style={{ fontSize: "14px" }}>
              End Date
            </span>{" "}
            <span className="px-3" style={{ fontSize: "13px" }}>
              {moment(finishDate).format(dateFormat)}
            </span>
          </label>
        </div>
      </div>
    );
  };

  function groupBy2(datas) {
    var theDatas = [];
    var key = 0;
    for (var i = 0; i < datas.length; i++) {
      theDatas.push({
        no: key + 1,
        email: datas[i].email,
        start_date: datas[i].start_date,
        finish_date: datas[i].finish_date,
        full_name: datas[i].full_name,
        id_timesheet: datas[i].id_timesheet,
        team_id: datas[i].team_id,
        team_name: datas[i].team_name,
        timesheet_id: datas[i].timesheet_id,
        user_id: datas[i].user_id,
      });
      key = key + 1;
      if (i !== datas.length - 1) {
        if (datas[i].finish_date !== datas[i + 1].finish_date) {
          key = 0;
        }
      }
    }
    return theDatas;
  }

  var resultsss2 = groupBy2(datas);
  function groupBy(array, f) {
    var groups = {};
    var num = 1;
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group, index) {
      return groups[group];
    });
  }

  var resultsss = groupBy(resultsss2, function (item) {
    return [item.start_date, item.finish_date];
  });

  const onChangeStart = (date, dateString) => {
    if (dateString == "") {
      setStartDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setStartDate(formatDate);
    }
  };
  const onChangeFinish = (date, dateString) => {
    if (dateString == "") {
      setFinishDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setFinishDate(formatDate);
    }
  };

  const clearFilter = () => {
    setStartDate("");
    setFinishDate("");
    setemployeeName("");
    setTeamValue(0);
    setDatas(originData);
    setlistEmployee([]);
  };
  useEffect(() => {
    if (startDate && !finishDate) {
      setDisabledButton(true);
    } else if (!startDate && finishDate) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [finishDate, startDate]);

  const filterUnopen = () => {
    setDatas(originData);
    if (startDate && finishDate && !employeeName && teamValue == 0) {
      const filteredViewAll = originData.filter(
        (data) => data.start_date >= startDate && data.finish_date <= finishDate
      );
      setDatas([...filteredViewAll]);
    } else if (startDate && finishDate && employeeName && teamValue == 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          data.start_date >= startDate &&
          data.finish_date <= finishDate &&
          // data.full_name.includes(employeeName)
          // Object.keys(data.full_name).some((k) =>
          //   String(data.full_name[k])
          //     .toLocaleLowerCase()
          //     .includes(employeeName.toLocaleLowerCase())
          // )
          data.full_name
            .toLocaleLowerCase()
            .match(employeeName.toLocaleLowerCase())
      );
      setDatas([...filteredViewAll]);
    } else if (employeeName && teamValue == 0) {
      // const filteredViewAll = originData.filter((data) =>
      //   Object.keys(data.full_name).some((k) =>
      //     String(data.full_name[k])
      //       .toLocaleLowerCase()
      //       .includes(employeeName.toLocaleLowerCase())
      //   )
      // );
      var filteredViewAll = originData.filter(function (data) {
        return data.full_name
          .toLocaleLowerCase()
          .match(employeeName.toLocaleLowerCase());
      });
      setDatas([...filteredViewAll]);
    } else if (startDate && finishDate && !employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          data.team_id === teamValue &&
          data.start_date >= startDate &&
          data.finish_date <= finishDate
      );
      setDatas([...filteredViewAll]);
    } else if (!employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) => data.team_id === teamValue
      );
      setDatas([...filteredViewAll]);
    } else if (employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          // Object.keys(data.full_name).some((k) =>
          //   String(data.full_name[k])
          //     .toLocaleLowerCase()
          //     .includes(employeeName.toLocaleLowerCase())
          // )
          data.full_name
            .toLocaleLowerCase()
            .match(employeeName.toLocaleLowerCase()) &&
          data.team_id === teamValue
      );
      setDatas([...filteredViewAll]);
    }
    setlistEmployee([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePagination = (page) => {
    setminindex((page - 1) * pageSize);
    setmaxindex(page * pageSize);
  };

  const itemRenderPaging = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  const columns = [
    {
      title: "Timesheets Date",
      dataIndex: [0],
      render: (dates) =>
        moment(dates.start_date).format("D MMM YYYY") +
        " - " +
        moment(dates.finish_date).format("D MMM YYYY"),
      // sorter: (a, b) =>
      // moment(a.start_date).unix() - moment(b.finish_date).unix(),
    },

    {
      title: "Total Employee",
      dataIndex: [0],
      render: (dates) => {
        const filteredViewAll = datas.filter(
          (data) =>
            data.start_date === dates.start_date &&
            data.finish_date === dates.finish_date
        );
        return filteredViewAll.length;
      },
      //   sorter: (a, b) => a.hourly_rate - b.hourly_rate,
      // sortDirections: ["descend", "ascend"],
      // key: "hourly_rate",
    },
  ];
  const listnama = [
    {
      title: "No",
      dataIndex: "no",
      render: (no) => no,
      // sorter: (a, b) => a.no - b.no,
      // sortDirections: ["descend", "ascend"],
      // key: "no",
    },
    {
      title: "Employees",
      dataIndex: "full_name",
      // sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      // sortDirections: ["descend", "ascend"],
      // key: "full_name",
    },
    {
      title: "Team",
      dataIndex: "team_name",
      // sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      // sortDirections: ["descend", "ascend"],
      // key: "team_name",
    },
  ];

  const handleNavigate = (datas, length) => {
    // var autoNum = Array.from(Array(listEmployeeLength).keys());
    // resultsss.push({ number: autoNum });
    setlistEmployee(datas);
    setlistEmployeeLength(length);
  };
  return (
    <div className="table-approve-page">
      <div className="d-flex pb-3">
        <div className=" text-start d-flex ">
          <DatePicker
            onChange={onChangeStart}
            placeholder="Start Date"
            className=""
            value={startDate !== "" ? moment(startDate, "YYYY-MM-DD") : null}
            format={dateFormat}
          />
          <label className=" text-dark-ds mx-2 py-1 ">Until</label>
          <DatePicker
            onChange={onChangeFinish}
            placeholder="End Date"
            className=""
            value={finishDate !== "" ? moment(finishDate, "YYYY-MM-DD") : null}
            format={dateFormat}
          />
          <Input
            className="input-aa ms-2 pe-3"
            placeholder="Employee Name"
            value={employeeName}
            onChange={(e) => setemployeeName(e.target.value)}
          />
          <Input.Group compact className="mx-2">
            <Select
              value={+teamValue}
              onChange={(value) => setTeamValue(value)}
            >
              <Option key={-1} value={0} style={{ display: "none" }}>
                Select Team
              </Option>
              {filterTeam?.map((team, index) => {
                return (
                  <Option key={index} value={team.team_id}>
                    {team.team_name}
                  </Option>
                );
              })}
            </Select>
          </Input.Group>

          <Button
            className="btn-aa px-3 me-2"
            disabled={disabledButton}
            onClick={filterUnopen}
          >
            <FaSearch disabled={disabledButton} onClick={filterUnopen} />
          </Button>

          <Button className="btn-aa px-lg-3 px-xl-3" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>
      <div className="col-12 text-dark-ds pb-3">
        <label style={{ fontSize: "14px" }}>Records : {datas.length}</label>
      </div>
      <div className="d-flex">
        <div
          className="card-table-ds px-3"
          style={{ width: "39vw", marginRight: "1.5em" }}
        >
          <div className="title-table">
            <p>
              <b>Timesheets List</b>
            </p>
          </div>
          <div className="table-new-unopened">
            {(loading === true && (
              <div className="example ">
                <Spin />{" "}
                <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
                  Please wait...
                </label>
              </div>
            )) ||
              (loading === false && (
                <>
                  <Table
                    columns={columns}
                    dataSource={resultsss}
                    pagination={{
                      pageSizeOptions: ["50", "100"],
                      showSizeChanger: true,
                      defaultPageSize: 50,
                      itemRender: itemRender,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleNavigate(record, record.length);
                        }, // click row
                      };
                    }}
                  />
                </>
              ))}
          </div>
        </div>

        <div className="card-table-ds px-3" style={{ width: "40.5vw" }}>
          <div className="title-table">
            <p>
              <b>
                Timesheets Date :{" "}
                {listEmployee.length !== 0
                  ? moment(listEmployee[0]["start_date"]).format("D MMM YYYY") +
                    " - " +
                    moment(listEmployee[0]["finish_date"]).format("D MMM YYYY")
                  : "-"}
              </b>
            </p>
          </div>
          <div className="table-new-unopened">
            {(loading === true && (
              <div className="example ">
                <Spin />{" "}
                <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
                  Please wait...
                </label>
              </div>
            )) ||
              (loading === false && (
                <>
                  <Table
                    columns={listnama}
                    dataSource={listEmployee}
                    // pagination={false}
                    pagination={{
                      pageSizeOptions: ["50", "100"],
                      showSizeChanger: true,
                      defaultPageSize: 50,
                      itemRender: itemRender,
                    }}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableNewUnOpenTimesheets;
