import { Table, Input, Button, Spin } from "antd";
import { FaSearch } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const dateFormat = "DD/MM/YYYY";
const columns = [
  {
    title: "Employee",
    dataIndex: "full_name",
    // sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    // sortDirections: ["descend", "ascend"],
    // key: "full_name",
  },
  {
    title: "Week Commencing",
    dataIndex: "start_date",
    render: (start_date) => moment(start_date).format(dateFormat),
    // sorter: (a, b) =>
    // moment(a.start_date).unix() - moment(b.start_date).unix(),
  },
  {
    title: "Month",
    dataIndex: "title",
    // sorter: (a, b) => a.title.localeCompare(b.title),
    // sortDirections: ["descend", "ascend"],
    // key: "title",
  },

  {
    title: "Team",
    dataIndex: "team_name",
    // sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    // sortDirections: ["descend", "ascend"],
    // key: "team_name",
  },

  {
    title: "Submitted",
    dataIndex: "submit_date",
    render: (submit_date) => moment(submit_date).format(dateFormat),
    // sorter: (a, b) =>
    // moment(a.submit_date).unix() - moment(b.submit_date).unix(),
  },
];
// const data = [];

// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     employee: `Lesti Bilar ${i}`,
//     weekcommencing: "11/01/2021",
//     month: `March `,
//     team: `Public Affairs`,
//     submitted: `18/01/2021`,
//   });
// }

const TableApprove = () => {
  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    axios
      .get("approve")
      .then((response) => {
        if (response) {
          // setData();
          setDatas(response.data);
          setOriginData(response.data);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchData();

    // return () => {
    //   second
    // }
  }, []);

  const [search, setSearch] = useState("");
  const clearFilter = () => {
    setSearch("");
    setDatas(originData);
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const searchData = () => {
    if (search !== "") {
      setSearch(search);
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      );
      setDatas([...dataSearch]);
    } else {
      setSearch(search);
      setDatas(originData);
    }
  };

  let navigate = useNavigate();

  function handleNavigate(id) {
    navigate("/approve-page/details", {
      state: { id: id, menu: "approvePage" },
    });
  }

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4 justify-content-between">
        <div className="text-start">
          <label style={{ fontSize: "14px" }}>Records : {datas.length}</label>
        </div>

        <div className="d-flex">
          <Input
            style={{ fontSize: "14px" }}
            className="input-aa pe-2"
            placeholder="Search"
            onChange={handleChange}
            value={search}
          />
          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={searchData}
          >
            <FaSearch onClick={searchData} />
          </Button>

          <Button htmlType="submit" className="btn-aa" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>

      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading == false && (
          <Table
            columns={columns}
            dataSource={datas}
            pagination={{
              pageSizeOptions: ["50", "100"],
              showSizeChanger: true,
              defaultPageSize: 50,
              itemRender: itemRender,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleNavigate(record.user_timesheet_id);
                }, // click row
              };
            }}
          />
        ))}
    </div>
  );
};

export default TableApprove;
