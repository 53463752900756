import { Button, Input } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import TableSettings from "../../components/table/TableSettings";
import "./SettingsPage.css";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const SettingsPage = () => {
  // const [gracePeriod, setgracePeriod] = useState([]);
  const [gracePeriodValue, setgracePeriodValue] = useState("");
  const [validation, setvalidation] = useState([]);
  useEffect(() => {
    axios
      .get("graceperiod")
      .then((response) => {
        setgracePeriodValue(response.data.days_overdue);
      })
      .catch((error) => {});

    // return () => {
    //   second
    // }
  }, []);
  const updateGracePeriod = () => {
    axios
      .post("graceperiod?days_overdue=" + gracePeriodValue)
      .then((response) => {
        setgracePeriodValue(response.data.days_overdue);
        window.location.reload();
      })
      .catch((error) => {
        setvalidation(error.response.data);
      });
  };

  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
        <div className="pt-2 title-table-ds ">
          <p>
            <b>Settings</b>
          </p>
        </div>
        <div className="card-table-ds mb-4 width-card-grace">
          <div className="px-3 table-approve-page">
            <div className="col-12 text-dark-ds pb-3">
              <b>Grace Period (Days)</b>
            </div>
            <div className="pe-1 text-start d-flex pb-3 ">
              <Input
                className="input-aa me-3"
                style={{ width: "30.8vw" }}
                placeholder=""
                value={gracePeriodValue}
                onChange={(e) => setgracePeriodValue(e.target.value)}
              />
              <Button
                className="btn-aa "
                style={{ width: "120px" }}
                onClick={updateGracePeriod}
              >
                Save
              </Button>
            </div>
            {validation?.days_overdue && (
              <p style={{ color: "red" }}>{validation.days_overdue}</p>
            )}
            <div className="col-12 text-secondary-ds pb-3">
              <label>
                The number of days before a timesheet becomes overdue.
              </label>
            </div>
          </div>
        </div>
        <div className="card-table-ds">
          <TableSettings />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
