import { Button, Modal, Table, Select, DatePicker, Input, Spin } from "antd";
import { FaPen } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const TableDetailStatusEmployee = () => {
  const [modalReject, setModalReject] = useState(false);
  const [modalAddItem, setModalAddItem] = useState(false);
  const [modalEditItem, setModalEditItem] = useState(false);
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [modalSaveItem, setModalSaveItem] = useState(false);
  const [modalDeleteTimesheet, setModalDeleteTimesheet] = useState(false);
  const [editable, setEditable] = useState({});
  const [oldHours, setOldHours] = useState("");
  const [oldEntityValue, setOldEntityValue] = useState("");
  const [oldProjectValue, setOldProjectValue] = useState("");
  const [entityValue, setEntityValue] = useState("");
  const [projectValue, setProjectValue] = useState("");
  var [dateAdd, setDateAdd] = useState("");
  const [dateEdit, setDateEdit] = useState("");
  const [hoursAdd, setHoursAdd] = useState("");
  const [notesAdd, setNotesAdd] = useState("");
  const [detailDashboard, setDetailDashboard] = useState([]);
  const [errorHoursInput, setErrorHoursInput] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [disabledAddModal, setDisabledAddModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalHours, setTotalHours] = useState("");
  const [projects, setProjects] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const [validation, setValidation] = useState([]);
  const [validationEdit, setValidationEdit] = useState([]);

  const [comment, setcomment] = useState("");
  const [validationReject, setValidationReject] = useState([]);
  const dateFormatList = "DD/MM/YYYY";
  // const dateFormatList2 = "YYYY-MM-DD";
  const [loading, setLoading] = useState(true);
  const [doStatus, setdoStatus] = useState(0);
  const [disabledApprove, setdisabledApprove] = useState(false);
  const [response, setresponse] = useState(null);
  const location = useLocation();

  axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
  let navigate = useNavigate();

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      width: 350,
      render: (_, item) => (
        <div>
          <li className="text-secondary-ds pb-2">
            Project &nbsp;&nbsp; &nbsp; &nbsp;: &nbsp;{" "}
            <span className="text-dark-ds"> {item.project_name}</span>
          </li>
          <li className="text-secondary-ds ">
            Billed &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; : &nbsp;{" "}
            <span className="text-dark-ds"> {item.entity_name}</span>
          </li>
        </div>
      ),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 450,
    },
    {
      title: "Hours",
      dataIndex: "hours",
    },

    {
      title: "% of Total Hours",
      dataIndex: "percent",
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (date) => <div>{moment(date).format(dateFormatList)}</div>,
    },

    {
      title: "",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="icon-container">
          <div
            className="icon-editable "
            onClick={() => {
              editFunction(record);
            }}
          >
            <div className="icon-solid btn">
              <FaPen />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const editFunction = (record) => {
    setDateEdit(record.date);
    setOldHours(record.hours);
    setOldEntityValue(record.entity_name);
    setOldProjectValue(record.project_name);
    setEditable({ ...record });
    setModalEditItem(true);
  };

  const onChangeDate = (date, dateString) => {
    if (dateString === "") {
      setDateAdd("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setDateAdd(formatDate);
    }
  };

  const onChangeEditDate = (date, dateString) => {
    if (dateString === "") {
      setDateEdit("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setDateEdit(formatDate);
    }
    // setDateEdit(dateString);
  };
  const handleApprove = async () => {
    setLoading(true);
    setdisabledApprove(true);
    await axios
      .post("approve/" + location.state.id)
      .then((response) => {
        if (response.status === 200) {
          setdisabledApprove(false);
          setLoading(false);
          if (location?.state?.menu === "approvePage") {
            navigate(-1);
          } else {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        setdisabledApprove(false);
        if (error.response.status === 422) {
          setresponse({
            message: "Please check your data again before approving.",
            status: error.response.status,
          });
        }
      });
  };
  const handleReject = async () => {
    setdisabledApprove(true);
    await axios
      .post("reject/" + location.state.id + "?comment=" + comment)
      .then((response) => {
        if (response.status === 200) {
          setdoStatus(3);
          setModalReject(false);
          setModalSaveItem(true);
          setresponse({
            message: "Timesheet has been successfully rejected.",
            status: response.status,
          });
          setdisabledApprove(false);
        }
      })
      .catch((error) => {
        setdisabledApprove(false);
        setValidationReject(error.response.data);
      });
  };
  function handleNavigate() {
    if (!location?.state?.from) {
      navigate(-1);
    } else {
      navigate(location.state.from, {
        state: { datas: location.state.datas, filters: location.state.filters },
      });
    }
  }

  const fetchData = async () => {
    setLoading(true);
    setDisabled(true);

    axios
      .get("project")
      .then((response) => {
        if (response) {
          setProjects(response.data);
        }
      })
      .catch((err) => {});

    axios
      .get("entity")
      .then((response) => {
        if (response) {
          setEntitys(response.data);
        }
      })
      .catch((err) => {});

    axios
      .get("timesheet/" + location.state.id)
      .then((response) => {
        if (response) {
          setData(response.data);
          setLoading(false);
        }
      })
      .catch((err) => {});

    axios
      .get("dashboard/" + location.state.id)
      .then((response) => {
        if (response) {
          setDetailDashboard(response.data);
          setDisabled(false);
        }
      })
      .catch((err) => {});
  };

  const totalHoursHandle = () => {
    const arrayBaru = data?.reduce(
      (prevValue, currentValue) => prevValue + currentValue.hours,
      0
    );
    setTotalHours(arrayBaru);
  };

  const differenceTime = () => {
    if (detailDashboard.work_contract_weeks === totalHours) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    totalHoursHandle();
    differenceTime();
  }, [data, detailDashboard, totalHours]);

  useEffect(() => {
    if (dateAdd !== "") {
      setDisabledAddModal(false);
    } else if (dateEdit !== "") {
      setDisabledAddModal(false);
    }
  }, [dateAdd, dateEdit]);

  const handleChangeHours = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const diffTime = detailDashboard.work_contract_weeks - totalHours;
    if (value > diffTime) {
      setErrorHoursInput("Input hours cannot exceed " + diffTime);
      setDisabledAddModal(true);
    } else if (value === 0) {
      setErrorHoursInput("Input cannot be 0/null");
      setDisabledAddModal(true);
    } else {
      setErrorHoursInput("");
      setDisabledAddModal(false);
    }
    setHoursAdd(value);
  };

  const handleChangeNotes = (e) => {
    setNotesAdd(e.target.value);
  };

  const addNewTimesheet = async () => {
    setDisabledAddModal(true);
    if (!dateAdd) {
      dateAdd = detailDashboard.start_date;
    }
    await axios
      .post(
        "dashboard/" +
          location.state.id +
          "?&id_task=" +
          null +
          "&id_entity=" +
          entityValue +
          "&id_project=" +
          projectValue +
          "&hours=" +
          hoursAdd +
          "&date=" +
          dateAdd +
          "&notes=" +
          encodeURIComponent(notesAdd)
      )
      .then((response) => {
        if (response.status === 200) {
          setDisabledAddModal(false);
          setModalAddItem(false);
          setModalSaveItem(true);
          setresponse({
            message: "Item has been successfully saved.",
            status: response.status,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (error.response.data.errorAddItem) {
            setDisabledAddModal(false);
            setModalAddItem(false);
            setModalSaveItem(true);
            setresponse({
              message: error.response.data.errorAddItem,
              status: error.response.status,
            });
          } else {
            setDisabledAddModal(false);
            setValidation(error.response.data);
          }
        }
      });
  };

  const editTimesheet = async () => {
    var id_entity = editable.id_entity;
    var id_project = editable.id_project;
    if (!id_entity) {
      id_entity = editable.entity_id;
    }
    if (!id_project) {
      id_project = editable.project_id;
    }
    if (!editable.notes) {
      editable.notes = "";
    }
    setDisabledAddModal(true);
    await axios
      .post(
        "timesheet/" +
          editable.user_task_id +
          "?&id_task=" +
          null +
          "&id_entity=" +
          id_entity +
          "&id_project=" +
          id_project +
          "&hours=" +
          editable.hours +
          "&date=" +
          dateEdit +
          "&notes=" +
          encodeURIComponent(editable.notes)
      )
      .then((response) => {
        if (response.status === 200) {
          setDisabledAddModal(false);
          setModalEditItem(false);
          setModalSaveItem(true);
          setresponse({
            message: response.data.message,
            status: response.status,
            timesheetStatus: response.data.timesheetStatus,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (error.response.data.errorEdit) {
            setDisabledAddModal(false);
            setModalEditItem(false);
            setModalSaveItem(true);
            setresponse({
              message: error.response.data.errorEdit,
              status: error.response.status,
            });
          } else {
            setDisabledAddModal(false);
            setValidationEdit(error.response.data);
          }
        }
      });
  };

  const confirmDeleteItem = () => {
    setModalEditItem(false);
    setModalDeleteItem(true);
  };

  const cancelDeleteItem = () => {
    setModalDeleteItem(false);
    setModalEditItem(true);
  };

  //================= APPROVE, REJECT DELETE navigate func =================
  const TIMESHEET_APPROVED_STATUS = 2;
  const DETAIL_DASHBOARD_APPROVED_STATUS = 3;
  const DO_STATUS_SUBMITTED = 2;
  const DO_STATUS_REJECTED = 3;
  const DO_STATUS_APPROVED = 4;
  const MENU_APPROVE_PAGE = "approvePage";
  const VIEW_ALL_PAGE_PATH = "/view-all-page";

  const closeSaveItem = () => {
    if (isTimesheetAndDetailDashboardApproved()) {
      navigate(VIEW_ALL_PAGE_PATH);
      return;
    }

    if (doStatus === DO_STATUS_SUBMITTED) {
      if (location?.state?.menu) {
        navigate(-1);
      } else {
        navigate(location.state.from, {
          state: {
            datas: location.state.datas,
            filters: location.state.filters,
          },
        });
      }
      return;
    }

    if (isDoStatusRejected()) {
      navigate(-1);
      return;
    }

    if (isDoStatus4()) {
      navigate(VIEW_ALL_PAGE_PATH);
      return;
    }

    window.location.reload();
  };

  const isTimesheetAndDetailDashboardApproved = () =>
    response?.timesheetStatus === TIMESHEET_APPROVED_STATUS &&
    detailDashboard.id_status === DETAIL_DASHBOARD_APPROVED_STATUS;

  const isDoStatusRejected = () =>
    doStatus === DO_STATUS_REJECTED &&
    location?.state?.menu === MENU_APPROVE_PAGE;

  const isDoStatus4 = () =>
    doStatus === DO_STATUS_APPROVED &&
    response?.timesheetStatus !== TIMESHEET_APPROVED_STATUS &&
    detailDashboard.id_status !== DETAIL_DASHBOARD_APPROVED_STATUS;
  //================= APPROVE, REJECT DELETE navigate func =================

  const deleteItemHandle = async () => {
    setDisabledAddModal(true);
    await axios
      .post("timesheet/item/" + editable.user_task_id)
      .then((response) => {
        if (response.status === 200) {
          setdoStatus(4);
          setModalDeleteItem(false);
          setModalSaveItem(true);
          setresponse({
            message: "Item has been successfully deleted.",
            status: response.status,
          });
          setDisabledAddModal(false);
        }
      })
      .catch((error) => {
        setDisabledAddModal(false);
        if (error.response.status === 422) {
          setDisabledAddModal(false);
          setresponse({
            message: "Please check your data again before deleting.",
            status: error.response.status,
          });
        }
      });
  };

  const deleteTimesheet = async () => {
    setDisabledAddModal(true);
    await axios
      .post("timesheet/delete/" + location.state.id)
      .then((response) => {
        if (response.status === 200) {
          setdoStatus(2);
          setModalDeleteTimesheet(false);
          setModalSaveItem(true);
          setresponse({
            message: "Timesheet has been successfully deleted.",
            status: response.status,
          });
          setDisabledAddModal(false);
        }
      })
      .catch((error) => {
        setDisabledAddModal(false);
        if (error.response.status === 422) {
          setresponse({
            message: "Please check your data again before deleting.",
            status: error.response.status,
          });
        }
      });
  };

  const disabledDate = (current) => {
    const start = moment(detailDashboard.start_date, "YYYY-MM-DD");
    const end = moment(detailDashboard.finish_date, "YYYY-MM-DD");
    return current < start || current > end;
  };

  return (
    <div className="px-3 table-approve-page">
      <div className="d-flex pb-4">
        <div className="col-4 text-start justify-start">
          <b className="text-status-open ">{detailDashboard.full_name}</b>
          <div className="d-flex mt-2">
            <div className="col-5 ">
              <ul>
                <li className="text-secondary-ds pb-2">No</li>
                <li className="text-secondary-ds pb-2">Staff Number</li>
                <li className="text-secondary-ds ">Team</li>
              </ul>
            </div>
            <div className="col-7">
              <ul>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>#
                  {detailDashboard?.user_timesheet_id}
                </li>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.staff_number}
                </li>
                <li className="text-dark-ds ">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.team_name}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-4 text-start justify-start">
          <b className="text-status-open ">
            {(detailDashboard.id_status === 1 && "Open") ||
              (detailDashboard.id_status === 2 && "In Progress") ||
              (detailDashboard.id_status === 3 && "Awaiting Approval") ||
              (detailDashboard.id_status === 4 && "Approved")}
          </b>
          <div className="d-flex mt-2">
            <div className="col-5 ">
              <ul>
                <li className="text-secondary-ds pb-2">Submitted Date</li>
                <li className="text-secondary-ds pb-2">Approved</li>
                <li className="text-secondary-ds ">Days Overdue</li>
              </ul>
            </div>
            <div className="col-7">
              <ul>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>
                  {(detailDashboard?.submit_date !== null &&
                    moment(detailDashboard?.submit_date).format(
                      dateFormatList
                    )) ||
                    ""}
                </li>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.status_id === 4 ? "yes" : "No"}
                </li>
                <li className="text-dark-ds ">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.remaining}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-4 text-start justify-start">
          <b className="text-status-open ">
            {moment(detailDashboard.start_date).format(dateFormatList)}
          </b>
          <div className="d-flex mt-2">
            <div className="col-5 ">
              <ul>
                <li className="text-secondary-ds pb-2">Month</li>
                <li className="text-secondary-ds pb-2">Week Commencing</li>
                <li className="text-secondary-ds ">Week Ending</li>
              </ul>
            </div>
            <div className="col-7">
              <ul>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.title}
                </li>
                <li className="text-dark-ds pb-2">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.start_date &&
                    moment(detailDashboard.start_date).format(dateFormatList)}
                </li>
                <li className="text-dark-ds ">
                  <span className="text-secondary-ds px-2">:</span>
                  {detailDashboard?.finish_date &&
                    moment(detailDashboard.finish_date).format(dateFormatList)}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Table columns={columns} dataSource={data} pagination={false} />

      <>
        {/* HIDE INI JIKA KONDISI INPROGRESS START DAN TABLE HARUS ADA DATA NYA*/}
        {detailDashboard.id_status !== 3 && (
          <>
            <div className="pt-3">
              <div className="col-6 offset-6 text-end">
                <Button
                  htmlType="submit"
                  className="btn-aa "
                  style={{ width: "137px" }}
                  onClick={() => setModalAddItem(true)}
                  disabled={disabled}
                >
                  Add
                </Button>
              </div>
            </div>
          </>
        )}

        {/* HIDE INI JIKA KONDISI INPROGRESS START  END*/}
        {/* {loading === && (
        
      )} */}
        <div
          className="col-2 offset-9  d-flex mt-5 mb-1 py-2"
          style={{ borderBottom: "2px solid #E9EAEC", width: "25%" }}
        >
          <div className="col-8 ps-4">
            <label className="text-dark-ds ">
              <b>Total Hours</b>
            </label>
          </div>
          <div className="col-4 ">
            <label className="text-dark-ds ">
              <b>{totalHours}</b>
            </label>
          </div>
        </div>

        <div
          className="col-2 offset-9  d-flex  mb-4 py-2"
          style={{ borderBottom: "2px solid #E9EAEC", width: "25%" }}
        >
          <div className="col-8 ps-4">
            <label className="text-dark-ds ">
              <b>Contracted Hours </b>
            </label>
          </div>
          <div className="col-4 ">
            <label className="text-dark-ds ">
              <b>{detailDashboard?.work_contract_weeks}</b>
            </label>
          </div>
        </div>
        <div className="d-flex pt-3">
          <div className="col-6 col-lg-3   text-start">
            <Button
              htmlType="submit"
              className="btn-aa px-5 "
              onClick={() => handleNavigate()}
            >
              Back
            </Button>
          </div>

          <div className="col-6 col-lg-9  text-end">
            {/* Display Ini Jika dalam kondisi Waiting Approval, Hide Ini jika kondisi OPEN dan In progress,  */}
            {detailDashboard.id_status === 3 && (
              <>
                <Button
                  htmlType="submit"
                  className="btn-aa px-5  "
                  style={{ width: "150px" }}
                  onClick={() => handleApprove()}
                  disabled={disabledApprove}
                >
                  {(loading === true && <Spin size="small" />) || "Approve"}
                </Button>
                <Button
                  htmlType="submit"
                  className="btn-aa px-5 mx-2"
                  onClick={() => setModalReject(true)}
                >
                  Reject
                </Button>
              </>
            )}

            <Button
              htmlType="submit"
              className="btn-aa px-5 "
              onClick={() => setModalDeleteTimesheet(true)}
            >
              Delete
            </Button>
          </div>
        </div>
      </>

      {/* Modal Reject */}
      <Modal
        title={null}
        centered
        visible={modalReject}
        onOk={() => setModalReject(false)}
        onCancel={() => setModalReject(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Reject Timesheets</b>
        </div>

        <div className="col-12 text-dark-ds">
          <label>Are you sure want to reject this Timesheet?</label>
        </div>

        <div className="col-12 text-dark-ds py-3">
          <textarea
            placeholder="Please give the reason why you reject this Timesheet.."
            style={{
              padding: "15px",
              height: "200px",
              width: "100%",
              border: "1px solid #E9EAEC",
              borderRadius: "6px",
            }}
            value={comment}
            onChange={(e) => setcomment(e.target.value)}
          ></textarea>
          {validationReject.comment && (
            <div style={{ color: "red" }}>{validationReject.comment}</div>
          )}
        </div>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={handleReject}
            disabled={disabledApprove}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalReject(false)}
          >
            No
          </Button>
        </div>
      </Modal>

      {/* Modal Add*/}
      <Modal
        title={null}
        centered
        visible={modalAddItem}
        onOk={() => setModalAddItem(false)}
        onCancel={() => setModalAddItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Add Item</b>
        </div>

        <label className="text-dark-ds pb-2">Entity</label>
        <div className="option-modal-add">
          <Input.Group compact className="input-modal pb-3">
            <Select
              defaultValue="Select Entity"
              onChange={(value) => setEntityValue(value)}
            >
              {entitys?.map((entity, index) => {
                return (
                  <>
                    <Option key={index} value={entity.entity_id}>
                      {entity.entity_name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Input.Group>
          {validation?.id_entity && (
            <p style={{ color: "red" }}>{validation.id_entity[0]}</p>
          )}
        </div>

        <label className="text-dark-ds pb-2">Project</label>
        <div className="option-modal-add">
          <Input.Group compact className="input-modal pb-3">
            <Select
              defaultValue="Select Project"
              onChange={(value) => setProjectValue(value)}
            >
              {projects?.map((project, index) => {
                return (
                  <>
                    <Option key={index} value={project.project_id}>
                      {project.project_name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Input.Group>
          {validation?.id_project && (
            <p style={{ color: "red" }}>{validation.id_project[0]}</p>
          )}
        </div>

        <label className="text-dark-ds pb-2">Date</label>
        <DatePicker
          allowClear={false}
          disabledDate={disabledDate}
          onChange={onChangeDate}
          defaultValue={moment(detailDashboard.start_date)}
          format={dateFormatList}
          className="input-modal mb-3"
        />
        {validation?.date && (
          <p style={{ color: "red" }}>{validation.date[0]}</p>
        )}

        <label className="text-dark-ds pb-2">Hours</label>
        <Input className="mb-3" value={hoursAdd} onChange={handleChangeHours} />

        {(validation?.hours && (
          <p style={{ color: "red" }}>{validation.hours[0]}</p>
        )) ||
          (errorHoursInput && (
            <p style={{ color: "red" }}>{errorHoursInput}</p>
          ))}

        <label className="text-dark-ds pb-2">Notes</label>
        <Input className="mb-4" value={notesAdd} onChange={handleChangeNotes} />
        {validation?.notes && (
          <p style={{ color: "red" }}>{validation.notes[0]}</p>
        )}

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={addNewTimesheet}
            disabled={disabledAddModal}
          >
            Save
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalAddItem(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      {/* MODAL EDIITT ITEM*/}
      <Modal
        title={null}
        centered
        visible={modalEditItem}
        onOk={() => setModalEditItem(false)}
        onCancel={() => setModalEditItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Edit Item</b>
        </div>

        <label className="text-dark-ds pb-2">Entity</label>
        <div className="option-modal-add">
          <Input.Group compact className="input-modal pb-3">
            <Select
              // defaultValue={editable.entity_id}
              value={+editable.entity_id}
              onChange={(value) => {
                setEditable((prev) => {
                  return { ...prev, entity_id: value };
                });
              }}
            >
              {entitys?.map((entity, index) => {
                return (
                  <>
                    <Option key={index} value={entity.entity_id}>
                      {entity.entity_name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Input.Group>
          {validationEdit?.entity_id && (
            <p style={{ color: "red" }}>{validationEdit.id_entity[0]}</p>
          )}
        </div>

        <label className="text-dark-ds pb-2">Project</label>

        <div className="option-modal-add">
          <Input.Group compact className="input-modal pb-3">
            <Select
              // defaultValue={editable.project_id}
              value={+editable.id_project}
              onChange={(value) => {
                setEditable((prev) => {
                  return { ...prev, id_project: value };
                });
              }}
            >
              {projects?.map((project, index) => {
                return (
                  <>
                    <Option key={index} value={project.project_id}>
                      {project.project_name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Input.Group>
          {validationEdit?.id_project && (
            <p style={{ color: "red" }}>{validationEdit.id_project[0]}</p>
          )}
        </div>

        <label className="text-dark-ds pb-2">Date</label>
        <DatePicker
          allowClear={false}
          disabledDate={disabledDate}
          onChange={onChangeEditDate}
          value={dateEdit ? moment(dateEdit) : moment(editable.date)}
          format={dateFormatList}
          className="input-modal mb-3"
        />
        {validationEdit?.date && (
          <p style={{ color: "red" }}>{validationEdit.date[0]}</p>
        )}

        <label className="text-dark-ds pb-2">Hours</label>
        <Input
          className="mb-3"
          value={editable.hours}
          onChange={(e) => {
            setEditable((prev) => {
              const value = e.target.value.replace(/\D/g, "");
              const diffTime = detailDashboard.work_contract_weeks - totalHours;
              const maxValue = diffTime + oldHours;
              if (value > maxValue) {
                setErrorHoursInput("Input hours cannot exceed " + maxValue);
                setDisabledAddModal(true);
              } else if (value === 0) {
                setErrorHoursInput("Input cannot be 0/null");
                setDisabledAddModal(true);
              } else {
                setErrorHoursInput("");
                setDisabledAddModal(false);
              }
              setHoursAdd(value);
              return { ...prev, hours: value };
            });
          }}
        />
        {(validationEdit?.hours && (
          <p style={{ color: "red" }}>{validationEdit.hours[0]}</p>
        )) ||
          (errorHoursInput && (
            <p style={{ color: "red" }}>{errorHoursInput}</p>
          ))}

        <label className="text-dark-ds pb-2">Notes</label>
        <Input
          className="mb-4"
          value={editable.notes}
          onChange={(e) => {
            setEditable((prev) => {
              return { ...prev, notes: e.target.value };
            });
          }}
        />
        {validationEdit?.notes && (
          <p style={{ color: "red" }}>{validationEdit.notes[0]}</p>
        )}

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={editTimesheet}
            disabled={disabledAddModal}
          >
            Save
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => confirmDeleteItem()}
          >
            Delete
          </Button>
        </div>
      </Modal>

      {/* MODAL DELETE TIMESHEET*/}
      <Modal
        title={null}
        centered
        visible={modalDeleteTimesheet}
        onOk={() => setModalDeleteTimesheet(false)}
        onCancel={() => setModalDeleteTimesheet(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3 ">
          <b>Delete Timesheet</b>
        </div>

        <label className="text-dark-ds pb-2 mb-3">
          Are you sure you want to delete this timesheet? <br />
          This timesheet will reset as new for the employee.
        </label>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={() => deleteTimesheet()}
            disabled={disabledAddModal}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalDeleteTimesheet(false)}
          >
            No
          </Button>
        </div>
      </Modal>

      {/* MODAL DELETE ITEM  */}
      <Modal
        title={null}
        centered
        visible={modalDeleteItem}
        onOk={() => setModalDeleteItem(false)}
        onCancel={() => cancelDeleteItem()}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3 ">
          <b>Delete Item</b>
        </div>
        {detailDashboard.id_status === 3 || detailDashboard.id_status === 4 ? (
          <label className="text-dark-ds pb-2 mb-3">
            Are you sure you want to delete this item? <br />
          </label>
        ) : (
          <label className="text-dark-ds pb-2 mb-3">
            Are you sure you want to delete this item?
          </label>
        )}

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={() => deleteItemHandle()}
            disabled={disabledAddModal}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => cancelDeleteItem()}
          >
            No
          </Button>
        </div>
      </Modal>

      {/* MODAL SAVE ITEM  */}
      <Modal
        title={null}
        centered
        visible={modalSaveItem}
        onCancel={() => closeSaveItem()}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3 ">
          {response?.status === 200 ? <b>Success!</b> : <b>Oops!</b>}
        </div>

        <label className="text-dark-ds pb-2 mb-3">{response?.message}</label>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => closeSaveItem()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableDetailStatusEmployee;
