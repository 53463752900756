import { Button, Input, Table, Modal, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";

const TableSettings = () => {
  const dateFormat = "DD/MM/YYYY";
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [data, setdata] = useState([]);
  const [gracePeriode, setgracePeriode] = useState();
  const [urlGif, seturlGif] = useState("");
  const [validation, setvalidation] = useState("");
  const [disabled, setdisabled] = useState(false);
  const [catId, setcatId] = useState("");
  const [disabledDelete, setdisabledDelete] = useState("");
  const [loading, setLoading] = useState(false);

  axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
  const fetchData = () => {
    setLoading(true);
    axios
      .get("gif")
      .then((response) => {
        setdata(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();

    // return () => {
    //   second
    // }
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "action",
      width: 100,

      render: (_, record) => (
        <div className="">
          <div className="icon-solid-danger">
            <BsFillTrashFill />
          </div>
          <div
            className="icon-editable-danger"
            onClick={() => deleteUrl(record)}
          ></div>
        </div>
      ),
    },
    {
      title: "Submited Date",
      dataIndex: "date_created",
      width: "18%",
      render: (date_created) => moment(date_created).format(dateFormat),
      sorter: (a, b) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
    },
    {
      title: "GIF",
      dataIndex: "url",
      width: 300,
      render: (text, record) => (
        <div className="">
          <img src={record.url} class="giphy-ds" alt="" />
        </div>
      ),
      sorter: (a, b) => a.url.localeCompare(b.url),
      sortDirections: ["descend", "ascend"],
      key: "url",
    },
    {
      title: "",
      dataIndex: "spacekosong",
      width: "",
      render: () => <div className=""></div>,
    },
  ];
  //   const data = [ {
  //     key:"1",
  //     submitdate:"05/23/2021",
  //     urlgif:"https://media1.giphy.com/media/6bAZXey5wNzBC/giphy.gif"

  //   },
  //   {
  //     key:"2",
  //     submitdate:"05/23/2022",
  //     urlgif:"https://media.giphy.com/media/MDJ9IbxxvDUQM/giphy.gif"
  //   },

  //   {
  //     key:"3",
  //     submitdate:"05/23/2022",
  //     urlgif:"https://media.giphy.com/media/8vQSQ3cNXuDGo/giphy.gif"
  //   }

  // ];

  const deleteUrl = (record) => {
    setcatId(record.cat_id);
    setModalDeleteItem(true);
  };
  const deleteHandle = () => {
    setdisabledDelete(true);
    axios
      .post("gif/delete/" + catId)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setdisabledDelete(false);
      });
  };
  const onChange = (date, dateString) => {};

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };
  const handleUrlGIfChange = (e) => {
    seturlGif(e.target.value);
  };

  const sendGif = () => {
    setdisabled(true);
    axios
      .post("gif?url=" + urlGif)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setvalidation(error.response.data);
        setdisabled(false);
      });
  };

  return (
    <div className="px-3 table-approve-page">
      <div className="text-dark-ds pb-3">
        <b>Insert GIF</b>
      </div>
      <div className="px-0 text-start d-flex pb-3">
        <Input
          className="input-aa me-3"
          style={{ width: "30.8vw" }}
          placeholder="http://"
          value={urlGif}
          onChange={handleUrlGIfChange}
        />
        <Button
          className="btn-aa  me-3"
          style={{ width: "120px" }}
          onClick={sendGif}
          disabled={disabled}
        >
          Insert GIF
        </Button>
      </div>
      {validation.url && <div style={{ color: "red" }}>{validation.url}</div>}

      <div className="col-12 text-secondary-ds pb-3">
        <label>Insert your GIF url above</label>
      </div>
      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading === false && (
          <>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: ["50", "100"],
                showSizeChanger: true,
                defaultPageSize: 50,
                itemRender: itemRender,
              }}
            />
          </>
        ))}

      {/* MODAL DELETE ITEM*/}
      <Modal
        title={null}
        centered
        visible={modalDeleteItem}
        onOk={() => setModalDeleteItem(false)}
        onCancel={() => setModalDeleteItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Delete Item</b>
        </div>

        <label className="text-dark-ds pb-2 mb-3 ">
          Are you sure want to delete this record?{" "}
        </label>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={deleteHandle}
            disabled={disabledDelete}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => setModalDeleteItem(false)}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableSettings;
