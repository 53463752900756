import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./table.css";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Button,
  Pagination,
  Modal,
} from "antd";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";
import { FaCheck, FaPen, FaSearch } from "react-icons/fa";
import ColumnGroup from "antd/lib/table/ColumnGroup";

const originData = [];

for (let i = 0; i < 5; i++) {
  originData.push({
    key: i.toString(),
    entities: `Edrward ${i}`,
  });
}

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }

  if (type === "next") {
    return <a>Next</a>;
  }

  return originalElement;
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <Input /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableRows = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");

  const [modalDeleteItem, setModalDeleteItem] = useState(false);

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    const newData = [...data];
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];

      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {}
  };

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    setEditingKey("");
  };

  const columns = [
    {
      // title: "operation",
      dataIndex: "operation",
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        const newData = [...data];
        const alldata = originData;
        if (newData.length > alldata.length) {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                // disabled={editingKey !== ""}
                title="Sure to cancel?"
                onConfirm={() => handleDelete(record.key)}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>

              {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm> */}
            </span>
          ) : (
            <span className="d-flex">
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
                style={{
                  marginRight: 8,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>
            </span>
          );
        } else {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 20,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              {/* <Popconfirm 
                  // disabled={editingKey !== ""}
                  title="Sure to cancel?" 
                  onConfirm={() => handleDelete(record.key)}>
                    <a >Cancel</a>
                </Popconfirm> */}

              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>
            </span>
          ) : (
            <span style={{ display: "flex" }}>
              <div
                className="me-3"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                <div className="icon-solid2">
                  <FaPen />
                </div>
                <div className="icon-editable2"></div>
              </div>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <div className="icon-solid-danger">
                  <BsFillTrashFill />
                </div>
                <div className="icon-editable-danger"></div>
              </Popconfirm>
            </span>
          );
        }
      },
    },

    {
      title: "Entities",
      dataIndex: "entities",
      editable: true,
    },
  ];

  // tambah row
  const newestData = data.length - 1;
  const [count, setCount] = useState(newestData);
  const handleAdd = () => {
    const newData = {
      key: count + 1,
    };
    setData([...data, newData]);
    setCount(count + 1);
    setEditingKey(newData.key);
  };

  // useEffect(()=>{
  //   edit()
  // }, [data])
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4">
        <div className="col-9 text-start">
          <label>Records : 50</label>
        </div>

        <div className="col-3 justify-end d-flex ">
          <Input className="input-aa" placeholder="Search" />
          <Button htmlType="submit" className="btn-aa mx-2">
            <FaSearch />
          </Button>

          <Button htmlType="submit" className="btn-aa">
            Clear
          </Button>
        </div>
      </div>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered={false}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            pageSizeOptions: ["50", "100"],
            showSizeChanger: true,
            defaultPageSize: 50,
            itemRender: itemRender,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="addRowBtn"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              order: 1,
            }}
          >
            <Button
              onClick={handleAdd}
              className="btn-aa mx-0"
              style={{
                marginTop: -145,
                marginRight: 16,
              }}
            >
              Add New Row
            </Button>
          </div>
        </div>
      </Form>

      {/* MODAL DELETE ITEM*/}
      <Modal
        title={null}
        centered
        visible={modalDeleteItem}
        onOk={() => setModalDeleteItem(false)}
        onCancel={() => setModalDeleteItem(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Delete Item</b>
        </div>

        <label className="text-dark-ds pb-2 ">
          Are you sure want to delete this record?{" "}
        </label>

        <div className=" text-center">
          <Button htmlType="submit" className="btn-aa-secondary px-4  ">
            Yes
          </Button>
          <Button
            htmlType="submit"
            className="btn-aa px-4 mx-2"
            onClick={() => setModalDeleteItem(false)}
          >
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableRows;
