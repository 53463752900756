import React from 'react'
import TableNewUnOpenTimesheets from '../../components/table/TableNewUnopenedTimesheets'
import TableNewUnOpenTimesheetS from '../../components/table/TableNewUnopenedTimesheets'
import TableUnOpentimesheet from '../../components/table/TableUnOpentimesheet'
import "./UnopenedTimesheetPage.css"

const UnopenedTimesheetPage = () => {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
      <div className="pt-2 title-table-ds">
          <p>
            <b>Unopened Timesheet</b>
          </p>
        </div>
        <div className='d-flex'>
        <div className=" pb-4 ">
          {/* <TableUnOpentimesheet/> */}
          <TableNewUnOpenTimesheets/>
        </div>
        {/* <div className="card-table-ds pb-4" >
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default UnopenedTimesheetPage