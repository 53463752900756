import React from 'react'
import TableEmployee from '../../components/table/TableEmployee'
import "./EmployeesPage.css"

const EmployeesPage = () => {
  return (
    <div className="right-side-wrapper">
    <div className="customePaddingContainer">
    <div className="pt-2 title-table-ds">
        <p>
          <b>Employees</b>
        </p>
      </div>
      <div className="card-table-ds">
        <TableEmployee/>
      </div>
    </div>
  </div>
  )
}

export default EmployeesPage