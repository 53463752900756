import {
  Button,
  Input,
  Table,
  DatePicker,
  Select,
  Space,
  Pagination,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import "./table.css";
import axios from "axios";
import moment from "moment";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const { Option } = Select;

const TableUnOpentimesheet = () => {
  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [teamValue, setTeamValue] = useState("");
  const [employeeName, setemployeeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  const [current, setcurrent] = useState(1);
  const [minindex, setminindex] = useState(0);
  const [maxindex, setmaxindex] = useState(0);
  const [totalPage, settotalPage] = useState(0);

  const [loading, setloading] = useState(false);
  var pageSize = 2;

  const dateFormat = "DD/MM/YYYY";
  const fetchData = async () => {
    setloading(true);
    axios
      .get("unopenedtimesheet")
      .then((response) => {
        if (response) {
          setDatas(response.data);
          setOriginData(response.data);
          settotalPage(resultsss.length / pageSize);
          setminindex(0);
          setmaxindex(pageSize);
          setloading(false);
        }
      })
      .catch((err) => {});
    axios
      .get("viewall/team")
      .then((response) => {
        if (response) {
          setFilterTeam(response.data);
        }
      })
      .catch((err) => {});
  };

  const { Column, ColumnGroup } = Table;

  const onChange = (date, dateString) => {};

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  const customeHeader = (startDate, finishDate) => {
    return (
      <div className="">
        <div className="text-center">
          <label>
            Start Date{" "}
            <span className="px-3" style={{ fontSize: "13px" }}>
              {" "}
              {moment(startDate).format(dateFormat)}
            </span>{" "}
            <span className="ps-5" style={{ fontSize: "14px" }}>
              End Date
            </span>{" "}
            <span className="px-3" style={{ fontSize: "13px" }}>
              {moment(finishDate).format(dateFormat)}
            </span>
          </label>
        </div>
      </div>
    );
  };

  function groupBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  var resultsss = groupBy(datas, function (item) {
    return [item.start_date, item.finish_date];
  });

  const onChangeStart = (date, dateString) => {
    if (dateString == "") {
      setStartDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setStartDate(formatDate);
    }
  };
  const onChangeFinish = (date, dateString) => {
    if (dateString == "") {
      setFinishDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setFinishDate(formatDate);
    }
  };

  const clearFilter = () => {
    setStartDate("");
    setFinishDate("");
    setemployeeName("");
    setTeamValue(0);
    setDatas(originData);
  };
  useEffect(() => {
    if (startDate && !finishDate) {
      setDisabledButton(true);
    } else if (!startDate && finishDate) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [finishDate, startDate]);

  const filterUnopen = () => {
    if (startDate && finishDate && !employeeName && teamValue == 0) {
      const filteredViewAll = originData.filter(
        (data) => data.start_date >= startDate && data.finish_date <= finishDate
      );
      setDatas([...filteredViewAll]);
    } else if (startDate && finishDate && employeeName && teamValue == 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          data.start_date >= startDate &&
          data.finish_date <= finishDate &&
          Object.keys(data).some((k) =>
            String(data[k])
              .toLocaleLowerCase()
              .includes(employeeName.toLocaleLowerCase())
          )
      );
      setDatas([...filteredViewAll]);
    } else if (employeeName && teamValue == 0) {
      const filteredViewAll = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(employeeName.toLocaleLowerCase())
        )
      );
      setDatas([...filteredViewAll]);
    } else if (startDate && finishDate && !employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          data.team_id === teamValue &&
          data.start_date >= startDate &&
          data.finish_date <= finishDate
      );
      setDatas([...filteredViewAll]);
    } else if (!employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) => data.team_id === teamValue
      );
      setDatas([...filteredViewAll]);
    } else if (employeeName && teamValue != 0) {
      const filteredViewAll = originData.filter(
        (data) =>
          Object.keys(data).some((k) =>
            String(data[k])
              .toLocaleLowerCase()
              .includes(employeeName.toLocaleLowerCase())
          ) && data.team_id === teamValue
      );
      setDatas([...filteredViewAll]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleChangePagination = (page) => {
    setcurrent(page);
    setminindex((page - 1) * pageSize);
    setmaxindex(page * pageSize);
  };

  const itemRenderPaging = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };

  return (
    <div className="px-3 table-approve-page">
      <div className="d-flex pb-3">
        <div className=" text-start d-flex ">
          <DatePicker
            onChange={onChangeStart}
            placeholder="Start Date"
            className=""
            value={startDate !== "" ? moment(startDate, "YYYY-MM-DD") : null}
            format={dateFormat}
          />
          <label className=" text-dark-ds mx-2 py-1 ">Until</label>
          <DatePicker
            onChange={onChangeFinish}
            placeholder="End Date"
            className=""
            value={finishDate !== "" ? moment(finishDate, "YYYY-MM-DD") : null}
            format={dateFormat}
          />
          <Input
            className="input-aa ms-2 pe-3"
            placeholder="Employee Name"
            value={employeeName}
            onChange={(e) => setemployeeName(e.target.value)}
          />
          <Input.Group compact className="mx-2">
            <Select
              value={+teamValue}
              onChange={(value) => setTeamValue(value)}
            >
              <Option key={-1} value={0} style={{ display: "none" }}>
                Select Team
              </Option>
              {filterTeam?.map((team, index) => {
                return (
                  <Option key={index} value={team.team_id}>
                    {team.team_name}
                  </Option>
                );
              })}
            </Select>
          </Input.Group>

          <Button
            className="btn-aa px-3 me-2"
            disabled={disabledButton}
            onClick={filterUnopen}
          >
            <FaSearch disabled={disabledButton} onClick={filterUnopen} />
          </Button>

          <Button className="btn-aa px-lg-3 px-xl-3" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>
      <div className="col-12 text-dark-ds pb-3">
        <label style={{ fontSize: "14px" }}>Records : {datas.length}</label>
      </div>
      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading == false && (
          <>
            {resultsss.map(
              (rs, index) =>
                index >= minindex &&
                index < maxindex && (
                  // return (
                  <div className="mb-2">
                    <Table key={index} dataSource={rs} pagination={false}>
                      <ColumnGroup
                        title={customeHeader(
                          rs[0].start_date,
                          rs[0].finish_date
                        )}
                        align="center"
                      >
                        <Column
                          className="bg-white"
                          title="Employee"
                          dataIndex="full_name"
                          key="timesheet_id"
                          align="left"
                        />
                        <ColumnGroup
                          title="Team"
                          dataIndex="team_name"
                          key="timesheet_id"
                          align="left"
                          className="bg-white"
                        />
                      </ColumnGroup>
                    </Table>
                  </div>
                  // );
                )
            )}

            <div id="pag-custome" className="pb-5">
              <Pagination
                // showSizeChanger
                pageSize={pageSize}
                current={current}
                total={resultsss.length}
                onChange={handleChangePagination}
                style={{ bottom: "0px" }}
                itemRender={itemRenderPaging}
              />
            </div>
          </>
        ))}
    </div>
  );
};

export default TableUnOpentimesheet;
