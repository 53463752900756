import React, { useEffect, useState } from "react";
import { RiApps2Fill } from "react-icons/ri";
import { IoDocumentText } from "react-icons/io5";
import {
  FaCalendarDay,
  FaFolderOpen,
  FaTasks,
  FaLandmark,
  FaBriefcase,
  FaUserFriends,
  FaUserAlt,
  FaCogs,
  FaCalculator,
} from "react-icons/fa";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import axios from "axios";
import Cookies from "universal-cookie";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const Sidebar = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  var token = cookies.get("token");
  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const location = useLocation();

  const fetchData = () => {
    axios
      .post("auth/me?token=" + token)
      .then((response) => {})
      .catch((error) => {
        if (error.response.status == 401) {
          logout();
        }
      });
    axios
      .get("approve")
      .then((response) => {
        if (response) {
          setDatas(response.data);
          setOriginData(response.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    fetchData();
    // const refresh = setInterval(async () => {
    //   token = cookies.get("token");
    //   const refreshAxios = axios.create({
    //     baseURL:
    //       "https://aa-api.datadrivensystems.co.uk/api/auth/refresh",
    //     headers: {
    //       Authorization: `Bearer ` + token,
    //     },
    //   });
    //   await refreshAxios
    //     .post()
    //     .then((response) => {
    //       cookies.set("token", response.data.access_token);
    //       cookies.set("expiredToken", response.data.expires_in);
    //     })
    //     .catch((error) => {
    //       logout();
    //     });
    // }, 15000);
    // return () => clearInterval(refresh);
  }, []);
  useEffect(() => {
    fetchData();
  }, [location?.pathname]);

  const logout = () => {
    axios
      .post("auth/logout?token=" + token)
      .then((response) => {
        cookies.remove("token");
        cookies.remove("expiredToken");
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        cookies.remove("token");
        cookies.remove("expiredToken");
        navigate("/");
        window.location.reload();
      });
  };
  return (
    <div>
      <div className="header-aa d-flex ">
        <div className="py-1">
          <img
            src="https://res.cloudinary.com/alcottimg/image/upload/v1664443094/Advertising%20Association/Icon%20Employee/aa-logo-black_ekitzx.png"
            alt="display image"
            style={{ display: "block", width: "180px" }}
            className="logo"
          />
        </div>
        <div className="px-3 ">
          {/* <NavLink to={"/"}> */}
          <img
            src="https://res.cloudinary.com/alcottimg/image/upload/v1664786696/Advertising%20Association/Icon%20Admin/Logout_omxnvb.svg"
            alt="display logout"
            style={{ display: "block", width: "20px", cursor: "pointer" }}
            className="logo"
            onClick={logout}
          />
          {/* </NavLink> */}
        </div>
      </div>
      <div className="d-flex">
        <div className="" style={{ width: "240px" }}>
          <div style={{ width: "auto" }} className="sidebar">
            <div className="sideBarMenuContainer">
              <div className="sideBarMenuItems">
                <div className="pt-4">
                  <NavLink
                    to={"/dashboard-page"}
                    key="index1"
                    className="link px-2 "
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <RiApps2Fill />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Dashboard
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="label-timesheets">
                <label className="">Timesheets</label>
              </div>

              <div className="sideBarMenuItems">
                <div className="">
                  <NavLink
                    to={"/approve-page"}
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <IoDocumentText />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      <div type="button" class="icon-button">
                        <span class="">Approve</span>
                        <span class="icon-button__badge">{datas.length}</span>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/newtimesheet-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaCalendarDay />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      New Timesheet
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/unopened-timesheet-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaFolderOpen />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Unopened Timesheet
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/view-all-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaTasks />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      View All
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="label-timesheets">
                <label className="">Setup</label>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/entities-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaLandmark />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Entities
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/projects-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaBriefcase />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Projects
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/teams-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaUserFriends />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Teams
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/employees-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaUserAlt />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Employees
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="sideBarMenuItems">
                <div className="pt-1">
                  <NavLink
                    to={"/settings-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaCogs />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Settings
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="label-timesheets">
                <label className="">Report</label>
              </div>

              <div className="sideBarMenuItems">
                <div className="">
                  <NavLink
                    to={"/cost-per-entities-page"}
                    key="index1"
                    className="link px-2 py-2"
                    // activeclassname="active"
                    style={({ isActive }) => ({
                      borderLeft: isActive ? "5px solid #26242A" : "none",
                    })}
                  >
                    <div className="icon">
                      <FaCalculator />
                    </div>
                    <div
                      style={{
                        display: "block",
                        fontColor: "#000000",
                      }}
                      className="link_text"
                    >
                      Cost Per Entities
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          {/* Version */}
          <div className="version-sidebar container version-wrapper ">
            <p>Version 2.4.8</p>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
