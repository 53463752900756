import { Button, Input, Table, DatePicker, Select, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
const { Option } = Select;

const TableViewAll = () => {
  const dateFormat = "DD/MM/YYYY";
  const columns = [
    {
      title: "Employee",
      dataIndex: "full_name",
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      sortDirections: ["descend", "ascend"],
      key: "full_name",
    },

    {
      title: "Week Commencing",
      dataIndex: "start_date",
      width: 300,
      render: (start_date) => moment(start_date).format(dateFormat),
      sorter: (a, b) =>
        moment(a.start_date).unix() - moment(b.start_date).unix(),
    },
    {
      title: "Team",
      dataIndex: "team_name",
      sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      sortDirections: ["descend", "ascend"],
      key: "team_name",
    },
    {
      title: "Overdue",
      dataIndex: "overdue",
      render: (_, { overdue }) => (
        <>
          {(overdue === 1 && (
            <label className="text-status-overdue">Yes</label>
          )) ||
            (overdue === 0 && <label>No</label>)}
        </>
      ),
      sorter: (a, b) => a.overdue - b.overdue,
      sortDirections: ["descend", "ascend"],
      key: "overdue",
    },

    {
      title: "Status",
      dataIndex: "status_id",
      render: (_, { status_id }) => (
        <>
          {(status_id === 1 && (
            <label className="text-status-open">Open</label>
          )) ||
            (status_id === 2 && (
              <label className="text-status-inprogress">In Progress</label>
            )) ||
            (status_id === 3 && (
              <label className="text-awaiting-approval">
                Awaiting Approval
              </label>
            )) ||
            (status_id === 4 && (
              <label className="text-approval">Approved</label>
            ))}
        </>
      ),
      sorter: (a, b) => a.status_id - b.status_id,
      sortDirections: ["descend", "ascend"],
      key: "status_id",
    },

    {
      title: "Submitted",
      dataIndex: "submit_date",
      render: (submit_date) =>
        submit_date != "Not submitted yet"
          ? moment(submit_date).format(dateFormat)
          : submit_date,
      sorter: (a, b) => {
        if (
          a.submit_date == "Not submitted yet" ||
          b.submit_date == "Not submitted yet"
        ) {
          return a.submit_date.length - b.submit_date.length;
        }
        return moment(a.submit_date).unix() - moment(b.submit_date).unix();
      },
    },
  ];

  const [originData, setOriginData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeNameDisplay, setemployeeNameDisplay] = useState("");
  const [teamNameDisplay, setteamNameDisplay] = useState("");
  const [statusNameDisplay, setstatusNameDisplay] = useState("");
  const [filterTeam, setFilterTeam] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [teamValue, setTeamValue] = useState(0);
  // const [filterOverdue, setFilterOverdue] = useState([]);
  const [overdueValue, setOverdueValue] = useState(-1);
  const [filterStatus, setFilterStatus] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeValue, setemployeeValue] = useState(null);

  const cookies = new Cookies();
  var location = useLocation();
  useEffect(() => {
    if (startDate && !finishDate) {
      setDisabledButton(true);
    } else if (!startDate && finishDate) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [finishDate, startDate]);

  const filterViewAll = () => {
    const filteredViewAll = originData.filter((data) => {
      const isStartDateValid = !startDate || data.start_date >= startDate;
      const isFinishDateValid = !finishDate || data.finish_date <= finishDate;
      const isEmployeeValid = !employeeValue || data.user_id == employeeValue;
      const isTeamValid = teamValue == 0 || data.id_team === teamValue;
      const isStatusValid = statusValue == 0 || data.id_status === statusValue;
      const isOverdueValid =
        overdueValue == -1 || data.overdue === overdueValue;

      return (
        isStartDateValid &&
        isFinishDateValid &&
        isEmployeeValid &&
        isTeamValid &&
        isStatusValid &&
        isOverdueValid
      );
    });

    setDatas([...filteredViewAll]);
  };
  //multiple filter view all end

  const handleChange = (e) => {
    setEmployeeName(e.target.value);
  };

  // const fetchData = async () => {
  //   setLoading(true);
  //   // Fungsi untuk mengambil data dari API
  //   function fetchDataChain(url, setDataFunction) {
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(url)
  //         .then((response) => {
  //           if (response) {
  //             setDataFunction(response.data);
  //             resolve(response.data);
  //           }
  //         })
  //         .catch((err) => {
  //           reject(err);
  //         });
  //     });
  //   }

  //   // Menggunakan Promise chaining untuk panggilan API secara berurutan
  //   fetchDataChain("employees", setFilterEmployees)
  //     .then(() => fetchDataChain("viewall/team", setFilterTeam))
  //     .then(() => fetchDataChain("viewall/status", setFilterStatus))
  //     .then(() => {
  //       // Semua panggilan API telah selesai dijalankan
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       // Handle error
  //       setLoading(false);
  //     });

  //   axios
  //     .get("viewall")
  //     .then((response) => {
  //       if (response) {
  //         const items = location?.state?.datas;
  //         const updatedDatas = response.data.map((data) => {
  //           if (!data.submit_date || data.submit_date === "Not submitted yet") {
  //             data.submit_date = "Not submitted yet";
  //           } else {
  //             data.submit_date = moment(data.submit_date).format("MM/DD/YYYY");
  //           }
  //           return data;
  //         });
  //         if (!items) {
  //           setDatas(updatedDatas);
  //         }
  //         setOriginData(updatedDatas);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((err) => {});
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fungsi untuk mengambil data dari API
      async function fetchDataChain(url, setDataFunction) {
        return axios.get(url).then((response) => {
          if (response) {
            setDataFunction(response.data);
            return response.data;
          }
        });
      }

      // Menggunakan Promise chaining untuk panggilan API secara berurutan
      await fetchDataChain("employees", setFilterEmployees);
      await fetchDataChain("viewall/team", setFilterTeam);
      await fetchDataChain("viewall/status", setFilterStatus);

      const response = await axios.get("viewall");
      if (response) {
        const items = location?.state?.datas;
        const updatedDatas = response.data.map((data) => {
          if (!data.submit_date || data.submit_date === "Not submitted yet") {
            data.submit_date = "Not submitted yet";
          } else {
            data.submit_date = moment(data.submit_date).format("MM/DD/YYYY");
          }
          return data;
        });

        if (!items) {
          setDatas(updatedDatas);
        }
        setOriginData(updatedDatas);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (originData) {
      filterViewAll();
    }
  }, [originData]);

  useEffect(() => {
    if (location?.state?.filters[0]) {
      setStartDate(location.state.filters[0]["startDate"]);
      setFinishDate(location.state.filters[1]["finishDate"]);
      setEmployeeName(location.state.filters[2]["employeeName"]);
      setTeamValue(location.state.filters[3]["teamValue"]);
      setOverdueValue(+location.state.filters[4]["overdueValue"]);
      setStatusValue(location.state.filters[5]["statusValue"]);
      setemployeeValue(location.state.filters[6]["employeeValue"]);
      setemployeeNameDisplay(location.state.filters[7]["employeeNameDisplay"]);
      setteamNameDisplay(location.state.filters[8]["teamNameDisplay"]);
      setstatusNameDisplay(location.state.filters[9]["statusNameDisplay"]);
    }
  }, [location?.state?.filters]);

  const onChangeStart = (date, dateString) => {
    if (dateString == "") {
      setStartDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setStartDate(formatDate);
    }
  };
  const onChangeFinish = (date, dateString) => {
    if (dateString == "") {
      setFinishDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setFinishDate(formatDate);
    }
  };

  let navigate = useNavigate();

  function handleNavigate(id) {
    navigate("/view-all-page/details", {
      state: {
        id: id,
        datas: datas,
        from: "/view-all-page",
        filters: [
          { startDate: startDate ? startDate : "" },
          { finishDate: finishDate ? finishDate : "" },
          { employeeName: employeeName ? employeeName : "" },
          { teamValue: teamValue ? teamValue : 0 },
          { overdueValue: overdueValue !== -1 ? overdueValue : -1 },
          { statusValue: statusValue ? statusValue : 0 },
          { employeeValue: employeeValue ? employeeValue : null },
          {
            employeeNameDisplay: employeeNameDisplay
              ? employeeNameDisplay
              : null,
          },
          {
            teamNameDisplay: teamNameDisplay ? teamNameDisplay : 0,
          },
          {
            statusNameDisplay: statusNameDisplay ? statusNameDisplay : 0,
          },
        ],
      },
    });
  }

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }

    if (type === "next") {
      return <a>Next</a>;
    }

    return originalElement;
  };
  const clearFilter = () => {
    setStartDate("");
    setFinishDate("");
    setemployeeValue(null);
    setemployeeNameDisplay(null);
    setTeamValue(0);
    setteamNameDisplay(0);
    setOverdueValue(-1);
    setStatusValue(0);
    setstatusNameDisplay(0);
    setDatas(originData);
  };
  const dateFormatList = "DD/MM/YYYY";
  const handleChangeEmployeeValue = (value, key) => {
    setemployeeValue(key.key);
    setemployeeNameDisplay(key.value);
  };
  const handleChangeTeamName = (value, key) => {
    setTeamValue(+key.key);
    setteamNameDisplay(key.value);
  };
  const handleChangeStatusName = (value, key) => {
    setStatusValue(+key.key);
    setstatusNameDisplay(key.value);
  };
  return (
    <div className="px-3 table-approve-page">
      <div className="d-flex pb-3">
        <div className=" text-start d-flex ">
          <DatePicker
            onChange={onChangeStart}
            placeholder="Start Date"
            className=""
            value={startDate !== "" ? moment(startDate) : null}
            format={dateFormatList}
          />
          <label className=" text-dark-ds mx-2 py-1">Until</label>
          <DatePicker
            onChange={onChangeFinish}
            placeholder="End Date"
            className=""
            value={finishDate !== "" ? moment(finishDate) : null}
            format={dateFormatList}
          />
          {/* <Input
            className=" ms-2 pe-2"
            placeholder="Employee Name"
            value={employeeName}
            onChange={handleChange}
          /> */}
          <Input.Group compact className="mx-2">
            <Select
              showSearch
              placeholder="Employee Name"
              // optionFilterProp="children"
              value={employeeNameDisplay ? employeeNameDisplay : null}
              onChange={(value, key) => handleChangeEmployeeValue(value, key)}
              filterOption={(input, option) =>
                (option?.label ?? "").toLocaleLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filterEmployees.map((employee, index) => {
                return {
                  key: employee.user_id,
                  value: employee.full_name,
                  label: employee.full_name,
                };
              })}
            />
          </Input.Group>
          <Input.Group
            compact
            className="mx-2"
            onChange={(value) => setFilterTeam(value)}
          >
            <Select
              // defaultValue="Select Team"
              // value={+teamValue}
              // onChange={(value) => setTeamValue(value)}
              value={teamNameDisplay ? teamNameDisplay : 0}
              onChange={(value, key) => handleChangeTeamName(value, key)}
            >
              <Option key={-1} value={0} style={{ display: "none" }}>
                Select Team
              </Option>
              {filterTeam?.map((team, index) => {
                return (
                  <Option key={team.team_id} value={team.team_name}>
                    {team.team_name}
                  </Option>
                );
              })}
            </Select>
          </Input.Group>

          <Input.Group compact className="">
            <Select
              value={+overdueValue}
              onChange={(value) => setOverdueValue(value)}
            >
              <Option key={-1} value={-1} style={{ display: "none" }}>
                Select Overdue
              </Option>
              <Option key={0} value={1}>
                Yes
              </Option>
              <Option key={1} value={0}>
                No
              </Option>
            </Select>
          </Input.Group>

          <Input.Group compact className="mx-2">
            <Select
              // value={+statusValue}
              // onChange={(value) => setStatusValue(value)}
              value={statusNameDisplay ? statusNameDisplay : 0}
              onChange={(value, key) => handleChangeStatusName(value, key)}
            >
              <Option key={-1} value={0} style={{ display: "none" }}>
                Select Status
              </Option>
              {filterStatus?.map((status, index) => {
                return (
                  <Option key={status.status_id} value={status.status_text}>
                    {status.status_text}
                  </Option>
                );
              })}
            </Select>
          </Input.Group>

          <Button
            className="btn-aa px-lg-3 px-xl-3 me-2"
            onClick={filterViewAll}
            disabled={disabledButton}
          >
            <FaSearch onClick={filterViewAll} disabled={disabledButton} />
          </Button>

          <Button className="btn-aa px-lg-3 px-xl-3" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>

      <div className="col-12 text-dark-ds pb-3">
        <label style={{ fontSize: "14px" }}>Records : {datas.length}</label>
      </div>
      {(loading === true && (
        <div className="example">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading === false && (
          <Table
            columns={columns}
            dataSource={datas}
            pagination={{
              pageSizeOptions: ["50", "100"],
              showSizeChanger: true,
              defaultPageSize: 50,
              itemRender: itemRender,
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleNavigate(record.user_timesheet_id);
                }, // click row
              };
            }}
          />
        ))}
    </div>
  );
};

export default TableViewAll;
