import React from "react";
import TableEntities from "../../components/table/TableEntities";
import TableRows from "../../components/table/tablerows";
import "./EntitiesPage.css";

const EntitiesPage = () => {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
        <div className="pt-2 title-table-ds">
          <p>
            <b>Entities</b>
          </p>
        </div>
        <div className="card-table-ds">
          <TableEntities />
        </div>
      </div>
    </div>
  );
};

export default EntitiesPage;
