import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./table.css";
import { CSVLink } from "react-csv";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Button,
  Pagination,
  Modal,
  Select,
  Spin,
  DatePicker,
  Progress,
} from "antd";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";
import { FaCheck, FaLeaf, FaPen, FaSearch } from "react-icons/fa";
import { RiSearch2Line } from "react-icons/ri";
import { Excel } from "antd-table-saveas-excel";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const onChange = (date, dateString) => {};

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }

  if (type === "next") {
    return <a>Next</a>;
  }

  return originalElement;
};

const TableCost = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [loading, setLoading] = useState(true);
  const dateFormat = "DD/MM/YYYY";
  const [minus, setminus] = useState("minus-top");

  const [filterEmployees, setFilterEmployees] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [filterEntities, setFilterEntities] = useState([]);
  const [filterProject, setFilterProject] = useState([]);

  const [employeeValue, setemployeeValue] = useState(null);
  const [TeamValue, setTeamValue] = useState("");
  const [entityValue, setentityValue] = useState("");
  const [projectValue, setprojectValue] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [totalData, settotalData] = useState(0);
  const [disabledDownload, setdisabledDownload] = useState(true);
  const [totalHour, settotalHour] = useState(0);
  const [totalCost, settotalCost] = useState(0);
  const [modalLoadDownload, setModalLoadDownload] = useState(false);
  const [downloadProcess, setDownloadProcess] = useState({
    stream: 0,
    allData: 1,
    status: "",
    notes: "",
  });
  const [formFilter, setformFilter] = useState(null);
  axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";

  const fetchData = () => {
    axios
      .get("employees")
      .then((response) => {
        if (response) {
          setFilterEmployees(response.data);
        }
      })
      .catch((err) => {});

    axios
      .get("viewall/team")
      .then((response) => {
        if (response) {
          setFilterTeam(response.data);
        }
      })
      .catch((err) => {});

    axios
      .get("entity")
      .then((response) => {
        if (response) {
          setFilterEntities(response.data);
        }
      })
      .catch((error) => {});

    axios
      .get("project")
      .then((response) => {
        if (response) {
          setFilterProject(response.data);
        }
      })
      .catch((error) => {});
  };

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    pageSize: 50,
  });

  let source = axios.CancelToken.source();
  useEffect(() => {
    setdisabledDownload(true);
    const changePaginate = async () => {
      let param1 = {
        page: paginationInfo.page,
        totalshow: paginationInfo.pageSize,
        startdate: startDate,
        enddate: finishDate,
        user_id: employeeValue,
        team_id: TeamValue,
        entity_id: entityValue,
        project_id: projectValue,
      };

      try {
        const response = await axios.get("cost", {
          params: param1,
          cancelToken: source.token,
        });

        const formatedDataPagination = response.data.costs.data.map(
          (dataPaginate) => ({
            ...dataPaginate,
            dates: moment(dataPaginate.date).format(dateFormat),
            submit_dates: moment(dataPaginate.submit_date).format(dateFormat),
            hourly_rates: "£ " + dataPaginate.hourly_rate,
            cost: parseFloat(dataPaginate.cost),
            costs: "£ " + dataPaginate.cost,
          })
        );

        setData(formatedDataPagination);
        settotalHour(response.data.total_hours);
        settotalCost(response.data.total_cost);
        settotalData(response.data.costs.total);
        setdisabledDownload(false);
        if (formatedDataPagination.length === 0) {
          setdisabledDownload(true);
        }
        setLoading(false);
      } catch (error) {}
    };
    changePaginate();

    return () => {
      setLoading(true);
      source.cancel();
    };
  }, [paginationInfo]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!data.length) {
      setminus("");
    } else {
      setminus("minus-top");
    }
    if (startDate && !finishDate) {
      setDisabledButton(true);
    } else if (!startDate && finishDate) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [startDate, finishDate, data]);

  const clearFilter = () => {
    setformFilter(null);
    setPaginationInfo({ page: 1, pageSize: paginationInfo.pageSize });
    setminus("minus-top");
    setStartDate("");
    setFinishDate("");
    setemployeeValue(null);
    setTeamValue(0);
    setentityValue(0);
    setprojectValue(0);
    setdisabledDownload(false);
  };

  const onChangeStart = (date, dateString) => {
    if (dateString == "") {
      setStartDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setStartDate(formatDate);
    }
  };

  const onChangeFinish = (date, dateString) => {
    if (dateString == "") {
      setFinishDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setFinishDate(formatDate);
      setminus("");
    }
  };

  const isEditing = (record) => record.key === editingKey;

  const columnsNote = [
    {
      title: "Timesheet",
      dataIndex: "user_timesheet_id",
      editable: true,
      sorter: (a, b) => a.user_timesheet_id - b.user_timesheet_id,
      sortDirections: ["descend", "ascend"],
      key: "user_timesheet_id",
    },

    {
      title: "Entity Name",
      dataIndex: "entity_name",
      editable: true,
      sorter: (a, b) => a.entity_name.localeCompare(b.entity_name),
      sortDirections: ["descend", "ascend"],
      key: "entity_name",
    },

    {
      title: "Project",
      dataIndex: "project_name",
      editable: true,
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
      sortDirections: ["descend", "ascend"],
      key: "project_name",
    },

    {
      title: "Employee",
      dataIndex: "full_name",
      editable: true,
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      sortDirections: ["descend", "ascend"],
      key: "full_name",
    },

    {
      title: "Team Name",
      dataIndex: "team_name",
      editable: true,
      sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      sortDirections: ["descend", "ascend"],
      key: "team_name",
    },

    {
      title: "Date of Task",
      dataIndex: "dates",
      editable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },

    {
      title: "Submit Date",
      dataIndex: "submit_dates",
      editable: true,
      sorter: (a, b) =>
        moment(a.submit_date).unix() - moment(b.submit_date).unix(),
    },

    {
      title: "Hours",
      dataIndex: "hours",
      editable: true,
      sorter: (a, b) => a.hours - b.hours,
      sortDirections: ["descend", "ascend"],
      key: "hours",
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      render: (hourly_rate) =>
        "£ " +
        Number(hourly_rate)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sorter: (a, b) => a.hourly_rate - b.hourly_rate,
      sortDirections: ["descend", "ascend"],
      key: "hourly_rate",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      editable: true,
      render: (cost) =>
        "£ " + cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sorter: (a, b) => a.cost - b.cost,
      sortDirections: ["descend", "ascend"],
      key: "cost",
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
  ];

  const columns = [
    {
      title: "Timesheet",
      dataIndex: "user_timesheet_id",
      editable: true,
      sorter: (a, b) => a.user_timesheet_id - b.user_timesheet_id,
      sortDirections: ["descend", "ascend"],
      key: "user_timesheet_id",
    },

    {
      title: "Entity Name",
      dataIndex: "entity_name",
      editable: true,
      sorter: (a, b) => a.entity_name.localeCompare(b.entity_name),
      sortDirections: ["descend", "ascend"],
      key: "entity_name",
    },

    {
      title: "Project",
      dataIndex: "project_name",
      editable: true,
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
      sortDirections: ["descend", "ascend"],
      key: "project_name",
    },

    {
      title: "Employee",
      dataIndex: "full_name",
      editable: true,
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      sortDirections: ["descend", "ascend"],
      key: "full_name",
    },

    {
      title: "Team Name",
      dataIndex: "team_name",
      editable: true,
      sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      sortDirections: ["descend", "ascend"],
      key: "team_name",
    },

    {
      title: "Date of Task",
      dataIndex: "dates",
      editable: true,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },

    {
      title: "Submit Date",
      dataIndex: "submit_dates",
      editable: true,
      sorter: (a, b) =>
        moment(a.submit_date).unix() - moment(b.submit_date).unix(),
    },

    {
      title: "Hours",
      dataIndex: "hours",
      editable: true,
      sorter: (a, b) => a.hours - b.hours,
      sortDirections: ["descend", "ascend"],
      key: "hours",
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      render: (hourly_rate) =>
        "£ " +
        Number(hourly_rate)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sorter: (a, b) => a.hourly_rate - b.hourly_rate,
      sortDirections: ["descend", "ascend"],
      key: "hourly_rate",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      editable: true,
      render: (cost) =>
        "£ " + cost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sorter: (a, b) => a.cost - b.cost,
      sortDirections: ["descend", "ascend"],
      key: "cost",
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  let collectingCPEstatus = true;
  const getAllDataCost = async () => {
    setdisabledDownload(true);
    setModalLoadDownload(true);
    const pageSize = 200;
    let page = 1;
    let lastPage = 1;
    let allData = [];

    try {
      while (true) {
        let config = {
          params: {
            page: page,
            totalshow: pageSize,
            startdate: formFilter?.start_date,
            enddate: formFilter?.end_date,
            user_id: formFilter?.user_id,
            team_id: formFilter?.team_id,
            entity_id: formFilter?.entity_id,
            project_id: formFilter?.project_id,
          },
          cancelToken: source.token,
        };
        const response = await axios.get("cost", config);

        const formatedDataPagination = response.data.costs.data.map(
          (dataPaginate) => ({
            ...dataPaginate,
            dates: moment(dataPaginate.date).format(dateFormat),
            submit_dates: moment(dataPaginate.submit_date).format(dateFormat),
            hourly_rates: "£ " + dataPaginate.hourly_rate,
            cost: parseFloat(dataPaginate.cost),
            costs: "£ " + dataPaginate.cost,
          })
        );

        allData = allData.concat(formatedDataPagination);
        lastPage = response.data.costs.last_page
          ? response.data.costs.last_page
          : 1;

        if (response.data.costs.next_page_url) {
          page++;
          setDownloadProcess({
            ...downloadProcess,
            stream: page,
            allData: lastPage,
          });
        } else if (response.data.costs.last_page === 1) {
          setDownloadProcess({
            ...downloadProcess,
            stream: page,
            allData: lastPage,
          });
          break;
        } else {
          break;
        }
      }
    } catch (error) {
      collectingCPEstatus = false;
      setDownloadProcess({
        ...downloadProcess,
        status: "exception",
        notes: "Download Failed",
      });
      setTimeout(() => {
        setModalLoadDownload(false);
      }, 1000);
    }

    setTimeout(() => {
      setModalLoadDownload(false);
    }, 1000);
    return allData;
  };

  const handleExport = async () => {
    const collectedDataCPE = await getAllDataCost();
    if (collectingCPEstatus) {
      const excel = new Excel();
      excel
        .addSheet("Cost Per Entity")
        .addColumns(columnsNote)
        .addDataSource(collectedDataCPE, {
          str2Percent: true,
        })
        .saveAs("Cost Per Entity.xlsx");
    }
    setTimeout(() => {
      setdisabledDownload(false);
      setDownloadProcess({
        stream: 0,
        allData: 1,
        status: "",
        notes: "",
      });
    }, 2000);
  };

  const handleFilterSearch = () => {
    setformFilter({
      ...formFilter,
      start_date: startDate,
      end_date: finishDate,
      user_id: employeeValue,
      team_id: TeamValue,
      entity_id: entityValue,
      project_id: projectValue,
    });
    setPaginationInfo({ page: 1, pageSize: paginationInfo.pageSize });
  };

  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4 justify-content-between">
        <div className="text-start" style={{ width: "200px" }}>
          <label style={{ fontSize: "14px" }}>Records : {totalData}</label>
        </div>

        <div className="justify-end d-flex ">
          <div className="d-flex ">
            <Input.Group compact className="">
              <DatePicker
                onChange={onChangeStart}
                placeholder="Start Date"
                className=""
                value={
                  startDate !== "" ? moment(startDate, "YYYY-MM-DD") : null
                }
                format={dateFormat}
              />
            </Input.Group>
            <label className=" text-dark-ds ms-2 py-1">Until</label>

            <Input.Group compact className="">
              <DatePicker
                onChange={onChangeFinish}
                placeholder="End Date"
                className=" ms-2 "
                value={
                  finishDate !== "" ? moment(finishDate, "YYYY-MM-DD") : null
                }
                format={dateFormat}
              />
            </Input.Group>
            <Input.Group compact className="mx-2">
              <Select
                showSearch
                placeholder="Employee Name"
                value={+employeeValue ? +employeeValue : null}
                onChange={(value) => setemployeeValue(value)}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLocaleLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={filterEmployees.map((employee) => {
                  return {
                    value: employee.user_id,
                    label: employee.full_name,
                  };
                })}
              />
            </Input.Group>

            <Input.Group compact className="me-2">
              <Select
                value={+TeamValue}
                placeholder="Team"
                onChange={(value) => setTeamValue(value)}
              >
                <Option key={-1} value={0} style={{ display: "none" }}>
                  Team
                </Option>
                {filterTeam?.map((team, index) => {
                  return (
                    <Option key={index} value={team.team_id}>
                      {team.team_name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>

            <Input.Group compact className="">
              <Select
                value={+entityValue}
                placeholder="Entity Name"
                onChange={(value) => setentityValue(value)}
              >
                <Option key={-1} value={0} style={{ display: "none" }}>
                  Entity Name
                </Option>
                {filterEntities?.map((entity, index) => {
                  return (
                    <Option key={index} value={entity.entity_id}>
                      {entity.entity_name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>

            <Input.Group compact className="ms-2">
              <Select
                value={+projectValue}
                placeholder="Project"
                onChange={(value) => setprojectValue(value)}
              >
                <Option key={-1} value={0} style={{ display: "none" }}>
                  Project
                </Option>

                {filterProject?.map((project, index) => {
                  return (
                    <Option key={index} value={project.project_id}>
                      {project.project_name}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
          </div>

          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={handleFilterSearch}
            disabled={disabledButton}
          >
            <FaSearch onClick={handleFilterSearch} disabled={disabledButton} />
          </Button>
          <Button className="btn-aa " onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>

      {/* {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading === false && ( */}
      <>
        <Form form={form} component={false}>
          <div className="table-with-button">
            <Table
              loading={loading}
              bordered={false}
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                total: totalData,
                pageSizeOptions: ["50", "100"],
                showSizeChanger: true,
                defaultPageSize: 50,
                current: paginationInfo?.page,
                itemRender: itemRender,
                onChange: (page, pageSize) => {
                  setPaginationInfo({ page, pageSize });
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="addRowBtn"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                order: 1,
              }}
            >
              {/* <CSVLink data={data} filename={"Employee.csv"}> */}
              <Button
                className={`${minus} btn-aa px-4 ms-3`}
                onClick={() => handleExport()}
                disabled={disabledDownload}
              >
                Download
              </Button>

              <div className={`${minus} d-flex px-5 `}>
                <label
                  className="pe-5"
                  style={{ fontSize: "20px", fontWeight: 1000 }}
                >
                  Overall
                </label>
                <label
                  className="pe-5 me-5"
                  style={{ fontSize: "20px", fontWeight: 1000 }}
                >
                  {totalHour}
                  {/* {sumHourCost} */}
                </label>
                <label
                  className="ps-5  "
                  style={{ fontSize: "20px", fontWeight: 1000 }}
                >
                  £ {totalCost}
                  {/* £ {fixedSumCost} */}
                </label>
              </div>
              {/* </CSVLink> */}
            </div>
          </div>
        </Form>
      </>
      {/* ))} */}
      <Modal
        title={null}
        centered
        visible={modalLoadDownload}
        onCancel={() => setModalLoadDownload(false)}
        width={400}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <div className="col-12 pb-3 ">
          <b>Downloading...</b>
        </div>
        <div id="progressMessage">
          Collecting data cost per entities. <br />
          <Progress
            percent={(
              (downloadProcess.stream / downloadProcess.allData) *
              100
            ).toFixed(0)}
            status={downloadProcess.status}
          />
          {downloadProcess.notes}
        </div>
      </Modal>
    </div>
  );
};

export default TableCost;
