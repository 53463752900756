import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./table.css";
import {
  Form,
  Input,
  Popconfirm,
  Table,
  Typography,
  Button,
  Modal,
  Select,
  DatePicker,
  InputNumber,
  Spin,
} from "antd";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillTrashFill } from "react-icons/bs";
import { FaCheck, FaPen, FaSearch, FaCircleNotch } from "react-icons/fa";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";
import TemplateEmail from "../email/TemplateEmail";
// import $ from "jquery";
// import "jquery-ui/ui/widgets/datepicker.js";
// import "jquery-ui/themes/base/all.css";

const { Option } = Select;

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }

  if (type === "next") {
    return <a>Next</a>;
  }

  return originalElement;
};

const TableEmployee = () => {
  const [modalAddEmployee, setModalAddEmployee] = useState(false);
  const [modalSaveEmployee, setModalSaveEmployee] = useState(false);

  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  // const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [originData, setoriginData] = useState([]);
  const [data, setData] = useState([]);
  const [staffNumber, setstaffNumber] = useState("");
  const [fullName, setfullName] = useState("");
  var [teamValue, setteamValue] = useState("");
  var [teamId, setteamId] = useState("");
  const [statusEmp, setStatusEmp] = useState("");
  const [statusActive, setStatusActive] = useState("");
  const [hourlyRate, sethourlyRate] = useState("");
  const [workingHour, setworkingHour] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [passwordConfirmation, setpasswordConfirmation] = useState("");
  const [errorpasswordConfirm, seterrorpasswordConfirm] = useState("");
  const [filterTeam, setfilterTeam] = useState("");
  const [teamDropdown, setTeamDropdown] = useState([]);
  const [search, setSearch] = useState("");
  const [condition, setCondition] = useState("");
  const [disabledSave, setdisabledSave] = useState(false);
  const [disableAdd, setdisableAdd] = useState(false);
  const [disableSubmit, setdisableSubmit] = useState(false);
  const [validation, setvalidation] = useState([]);
  const [loading, setLoading] = useState(false);

  const [createdDate, setCreatedDate] = useState("");
  const [addStatusActive, setAddStatusActive] = useState("0");
  const [displaydate, setDisplayDate] = useState("");

  const onChangeCreatedDate = (date, dateString) => {
    if (dateString == "") {
      setCreatedDate("");
    } else {
      var formatDate = moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
      setCreatedDate(formatDate);
    }
  };

  axios.defaults.baseURL = "https://aa-api.datadrivensystems.co.uk/api/";
  const [minus, setminus] = useState("minus-top");

  const navigate = useNavigate();
  const fetchData = () => {
    let datas = [];
    setLoading(true);
    setdisableAdd(true);
    axios
      .get("employees")
      .then((response) => {
        setCount(response.data.length - 1);
        setdisableAdd(false);
        setLoading(false);
        response.data.map((dataku) => {
          if (dataku.inactive_at == null) {
            dataku.inactiveAt = 0;
          } else {
            dataku.inactiveAt = 1;
          }
          datas.push(dataku);
        });
        setData(datas);
        setoriginData(datas);
      })
      .catch((error) => {});
    axios
      .get("viewall/team")
      .then((response) => {
        setTeamDropdown(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const successEmail = async () => {
    const emailTemplate = await TemplateEmail(email, password);
    if (emailTemplate == undefined) {
      setdisableSubmit(false);
      setModalAddEmployee(false);
      setModalSaveEmployee(true);
    }
  };

  const closeSaveEmployee = () => {
    setModalSaveEmployee(false);
    window.location.reload(true);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      dataIndex === "inactiveAt" ? (
        <Select style={{ width: 120 }}>
          <Option value={0}>Active</Option>
          <Option value={1}>Inactive</Option>
        </Select>
      ) : dataIndex === "team_name" ? (
        teamDropdown.length > 0 ? (
          <Select
            style={{ width: 120 }}
            options={teamDropdown.map((team) => {
              return {
                value: team.team_id,
                label: team.team_name,
              };
            })}
            onChange={(e) => setteamId(e)}
          />
        ) : (
          <Input />
        )
      ) : dataIndex === "created_at" ? (
        <Form.Item
          style={{ margin: 0 }}
          name="editable create date"
          rules={[
            {
              required: true,
              message: `Please Input Date!`,
            },
          ]}
        >
          <DatePicker
            placeholder="DD-MM-YYYY"
            formate="DD-MM-YYYY"
            onChange={onChangeCreatedDate}
            defaultValue={moment(record[dataIndex], "YYYY-MM-DD")}
            // value={createdDate !== ""?moment(createdDate, "YYYY-MM-DD"):null}
            format={dateFormatList}
          />
        </Form.Item>
      ) : (
        <Input />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  // tambah row
  const newestData = data.length - 1;
  const [count, setCount] = useState(newestData);
  const handleAdd = () => {
    const newData = {
      key: count + 1,
    };
    setData([...data, newData]);
    setCount(count + 1);
    setEditingKey(newData.key);
    setCondition("add");
    form.resetFields();
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const searchData = () => {
    if (search !== "" && statusActive) {
      const dataSearch = originData.filter(
        (data) =>
          data.inactiveAt == statusActive &&
          Object.keys(data).some((k) =>
            String(data[k])
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
      );
      if (!dataSearch.length) {
        setminus("");
      } else {
        setminus("minus-top");
      }
      setData([...dataSearch]);
    } else if (search !== "") {
      setSearch(search);
      const dataSearch = originData.filter((data) =>
        Object.keys(data).some((k) =>
          String(data[k])
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      );
      if (!dataSearch.length) {
        setminus("");
      } else {
        setminus("minus-top");
      }
      setData([...dataSearch]);
    } else if (statusActive) {
      // setSearch(search);
      const dataSearch = originData.filter(
        (data) => data.inactiveAt == statusActive
      );
      if (!dataSearch.length) {
        setminus("");
      } else {
        setminus("minus-top");
      }
      setData([...dataSearch]);
    } else {
      setminus("minus-top");
      setSearch(search);
      setData(originData);
    }
  };

  const clearFilter = () => {
    setminus("minus-top");
    setSearch("");
    setStatusActive("");
    setData(originData);
  };

  useEffect(() => {
    let datas = [];
    axios
      .get("employees")
      .then((response) => {
        response.data.map((dataku) => {
          if (dataku.inactive_at == null) {
            dataku.inactiveAt = 0;
          } else {
            dataku.inactiveAt = 1;
          }
          datas.push(dataku);
        });
        setoriginData(datas);
      })
      .catch((error) => {});
  }, [data]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      email: "",
      staff_number: "",
      full_name: "",
      team_name: "",
      hourly_rate: "",
      work_contract_weeks: "",
      // datecreated: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    const newData = [...data];
    setEditingKey("");
  };

  const sumbitEmployee = async () => {
    setdisableSubmit(true);
    var current = moment().format("YYYY-MM-DD HH:mm:ss");
    if (!teamValue) {
      teamValue = teamDropdown[0].team_id;
    }
    if (passwordConfirmation == "") {
      seterrorpasswordConfirm(true);
    } else {
      seterrorpasswordConfirm(false);
    }
    var status = null;
    if (addStatusActive == 0) {
      status = null;
    } else {
      status = current;
    }
    await axios
      .post(
        "employees?id_team=" +
          teamValue +
          "&staff_number=" +
          encodeURIComponent(staffNumber) +
          "&full_name=" +
          encodeURIComponent(fullName) +
          "&email=" +
          email +
          "&hourly_rate=" +
          hourlyRate +
          "&work_contract_weeks=" +
          workingHour +
          "&password=" +
          encodeURIComponent(password) +
          "&password_confirmation=" +
          encodeURIComponent(passwordConfirmation) +
          "&inactive_at=" +
          status
      )
      .then((response) => {
        successEmail();
        // window.location.reload();
      })
      .catch((error) => {
        setvalidation(error.response.data);
        setdisableSubmit(false);
      });
  };

  const save = async (key) => {
    try {
      // setdisabledSave(true);
      // const row = await form.validateFields();
      // const newData = [...data];
      // const index = newData.findIndex((item) => key === item.key);
      // if (index > -1) {
      //   axios
      //     .post(
      //       "employee?entity_name=" +
      //         row.entity_name
      //     )
      //     .then((response) => {
      //       const item = newData[index];
      //       newData.splice(index, 1, { ...item, ...row });
      //       setData(newData);
      //       setEditingKey("");
      //       setdisabledSave(false);
      //     })
      //     .catch((error) => {
      //
      //     });
      // } else {
      //   newData.push(row);
      //   setData(newData);
      //   setEditingKey("");
      // }
    } catch (errInfo) {}
  };

  const saveEdit = async (key, record) => {
    if (!teamId) {
      teamId = record.team_id;
    }
    try {
      let datas = [];
      setdisabledSave(true);
      var current = moment().format("YYYY-MM-DD HH:mm:ss");
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      var statusEmpp = row.inactiveAt;
      if (row.inactiveAt == 1) {
        statusEmpp = current;
      } else {
        statusEmpp = null;
      }
      var date = null;
      if (createdDate == "") {
        date = row.created_at;
      } else {
        date = createdDate;
      }
      if (index > -1) {
        axios
          .post(
            "employees/" +
              record.user_id +
              "?id_team=" +
              parseInt(teamId) +
              "&email=" +
              row.email +
              "&staff_number=" +
              encodeURIComponent(row.staff_number) +
              "&full_name=" +
              encodeURIComponent(row.full_name) +
              "&hourly_rate=" +
              row.hourly_rate +
              "&work_contract_weeks=" +
              row.work_contract_weeks +
              "&inactive_at=" +
              statusEmpp +
              "&created_at=" +
              date
          )
          .then((response) => {
            setEditingKey("");
            setdisabledSave(false);
            setCreatedDate("");
            form.resetFields();
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            axios
              .get("employees")
              .then((response) => {
                // setData(response.data);
                response.data.map((dataku) => {
                  if (dataku.inactive_at == null) {
                    dataku.inactiveAt = 0;
                  } else {
                    dataku.inactiveAt = 1;
                  }
                  datas.push(dataku);
                });
                setData(datas);
              })
              .catch((error) => {});
          })
          .catch((error) => {
            setdisabledSave(false);
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      setdisabledSave(false);
    }
  };

  const handleDelete = (key, id) => {
    axios
      .post("employees/delete/" + id)
      .then((response) => {})
      .catch((error) => {});
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    setEditingKey("");
  };

  const columns = [
    {
      // title: "operation",
      dataIndex: "operation",
      width: 100,
      render: (_, record) => {
        const editable = isEditing(record);
        if (condition == "add") {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                disabled={disabledSave}
                onClick={() => save(record.key)}
                style={{
                  marginRight: 10,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck disabled={disabledSave} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                // disabled={editingKey !== ""}
                disabled={disabledSave}
                title="Sure to cancel?"
                onConfirm={() => handleDelete(record.key, record.user_id)}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp disabled={disabledSave} />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>

              {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm> */}
            </span>
          ) : (
            <span className="d-flex">
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
                style={{
                  marginRight: 10,
                  paddingTop: 2,
                }}
              >
                <div className="icon-solid2">
                  <FaPen disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key, record.user_id)}
              >
                <div className="icon-solid-danger3">
                  <BsFillTrashFill disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable-danger3"></div>
              </Popconfirm>
            </span>
          );
        } else {
          return editable ? (
            <span className="d-flex">
              <Typography.Link
                disabled={disabledSave}
                onClick={() => saveEdit(record.key, record)}
                style={{
                  marginRight: 20,
                  paddingTop: 5,
                }}
              >
                <div className="icon-solid2">
                  <FaCheck disabled={disabledSave} />
                </div>
                <div className="icon-editable2"></div>
              </Typography.Link>

              {/* <Popconfirm 
                  // disabled={editingKey !== ""}
                  title="Sure to cancel?" 
                  onConfirm={() => handleDelete(record.key)}>
                    <a >Cancel</a>
                </Popconfirm> */}

              <Popconfirm
                title="Sure to cancel?"
                onConfirm={cancel}
                disabled={disabledSave}
              >
                <div className="icon-solid-danger2 ">
                  <IoCloseSharp disabled={disabledSave} />
                </div>
                <div className="icon-editable-danger2"></div>
              </Popconfirm>
            </span>
          ) : (
            <span style={{ display: "flex" }}>
              <div
                className="me-3"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                <div className="icon-solid2">
                  <FaPen disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable2"></div>
              </div>

              <Popconfirm
                disabled={editingKey !== ""}
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key, record.user_id)}
              >
                <div className="icon-solid-danger">
                  <BsFillTrashFill disabled={editingKey !== ""} />
                </div>
                <div className="icon-editable-danger"></div>
              </Popconfirm>
            </span>
          );
        }
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      editable: true,
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      key: "email",
      // sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
    },
    {
      title: "Staff Number",
      dataIndex: "staff_number",
      editable: true,
      sorter: (a, b) => a.staff_number.localeCompare(b.staff_number),
      sortDirections: ["descend", "ascend"],
      key: "staff_number",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      editable: true,
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      sortDirections: ["descend", "ascend"],
      key: "full_name",
    },
    {
      title: "Team",
      dataIndex: "team_name",
      editable: true,
      sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      sortDirections: ["descend", "ascend"],
      key: "team_name",
    },

    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      editable: true,
      // render: (hourly_rate) => {
      //   return <>£{" " + hourly_rate}</>;
      // },
      render: (hourly_rate) =>
        "£ " +
        Number(hourly_rate)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,"),
      sorter: (a, b) => a.hourly_rate - b.hourly_rate,
      sortDirections: ["descend", "ascend"],
      key: "hourly_rate",
    },

    {
      title: "Working Hours per Week",
      dataIndex: "work_contract_weeks",
      editable: true,
      sorter: (a, b) => a.work_contract_weeks - b.work_contract_weeks,
      sortDirections: ["descend", "ascend"],
      key: "work_contract_weeks",
    },

    {
      title: "Date Created",
      dataIndex: "created_at",
      width: 190,
      editable: true,
      render: (created_at) => moment(created_at).format(dateFormat),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },

    {
      title: "Status",
      dataIndex: "inactiveAt",
      editable: true,
      width: 110,
      sorter: (a, b) => a.inactiveAt - b.inactiveAt,
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          {status == 0 && <label>Active</label>}
          {status != 0 && <label>Inactive</label>}
        </>
      ),
      // sorter: (a, b) => a.team_name.localeCompare(b.team_name),
      // sortDirections: ["descend", "ascend"],
    },
  ];

  const dateFormatList = "DD/MM/YYYY";

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        inputType: col.dataIndex === "team_name" ? "number" : "text",
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const resetHandle = () => {
    setstaffNumber("");
    setfullName("");
    setteamValue("");
    sethourlyRate("");
    setworkingHour("");
    setemail("");
    setpassword("");
    setpasswordConfirmation("");
    setModalAddEmployee(false);
  };
  return (
    <div className="px-3  table-ds-page">
      <div className="d-flex pb-4 justify-content-between">
        <div className="text-start">
          <label style={{ fontSize: "14px" }}>Records : {data.length}</label>
        </div>

        <div className="justify-end d-flex">
          <Input
            className="input-aa pe-2"
            placeholder="Search"
            onChange={handleChange}
            value={search}
          />

          <Input.Group compact className="mx-2">
            <Select
              // value={+teamValue}
              placeholder="Status"
              value={statusActive ? statusActive : 0}
              onChange={(value, key) => setStatusActive(value, key)}
            >
              <Option key={-1} value={0} style={{ display: "none" }}>
                Status
              </Option>
              <Option value="0">Active</Option>

              <Option value="1">Inactive</Option>
            </Select>
          </Input.Group>

          <Button
            htmlType="submit"
            className="btn-aa mx-2"
            onClick={searchData}
          >
            <FaSearch onClick={searchData} />
          </Button>

          <Button htmlType="submit" className="btn-aa" onClick={clearFilter}>
            Clear
          </Button>
        </div>
      </div>

      {(loading === true && (
        <div className="example ">
          <Spin />{" "}
          <label style={{ color: "#A3A3A3", fontSize: "14px" }}>
            Please wait...
          </label>
        </div>
      )) ||
        (loading === false && (
          <Form form={form} component={false}>
            <div className="table-with-button">
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowKey="user_id"
                bordered={false}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  pageSizeOptions: ["50", "100"],
                  showSizeChanger: true,
                  defaultPageSize: 50,
                  itemRender: itemRender,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="addRowBtn"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  order: 1,
                }}
              >
                <Button
                  onClick={() => setModalAddEmployee(true)}
                  disabled={editingKey !== "" || disableAdd}
                  className={`${minus} btn-aa mx-0 `}
                >
                  Add Employee
                </Button>
              </div>
            </div>
          </Form>
        ))}

      {/* Modal Add*/}
      <Modal
        title={null}
        centered
        visible={modalAddEmployee}
        onOk={() => setModalAddEmployee(false)}
        onCancel={() => setModalAddEmployee(false)}
        width={400}
        footer={null}
      >
        <div className="col-12 pb-3">
          <b>Add Employee</b>
        </div>
        <label className="text-dark-ds pb-2">Staff Number</label>
        <Input
          className="mb-3"
          value={staffNumber}
          onChange={(e) => setstaffNumber(e.target.value)}
        />
        {validation.staff_number && (
          <div style={{ color: "red" }}>{validation.staff_number}</div>
        )}
        <label className="text-dark-ds pb-2">Full Name</label>
        <Input
          className="mb-3"
          value={fullName}
          onChange={(e) => setfullName(e.target.value)}
        />
        {validation.full_name && (
          <div style={{ color: "red" }}>{validation.full_name}</div>
        )}
        <label className="text-dark-ds pb-2">Team</label>
        <div className="option-modal-add">
          <Input.Group
            compact
            className="input-modal pb-3"
            onChange={(value) => setfilterTeam(value)}
          >
            <Select
              defaultValue={
                teamValue != ""
                  ? +teamDropdown[0]?.team_id
                  : +teamDropdown[0]?.team_id
              }
              // value={
              //   (+teamValue == 0 && +teamDropdown[0]?.team_id) || +teamValue
              // }
              onChange={(value) => setteamValue(value)}
            >
              {teamDropdown.map((team, index) => {
                return (
                  <>
                    <Option key={index} value={team.team_id}>
                      {team.team_name}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Input.Group>
          {validation.team_id && (
            <div style={{ color: "red" }}>{validation.team_id}</div>
          )}
        </div>

        <div className="d-flex">
          <div className="col-6 pe-1">
            <label className="text-dark-ds pb-2">Hourly Rate</label>
            <Input
              type="number"
              className="mb-3"
              value={hourlyRate}
              onChange={(e) => sethourlyRate(e.target.value)}
            />
            {validation.hourly_rate && (
              <div style={{ color: "red" }}>{validation.hourly_rate}</div>
            )}
          </div>

          <div className="col-6 ps-1">
            <label className="text-dark-ds pb-2">Working Hours per Week</label>
            <Input
              type="number"
              className=" mb-3"
              value={workingHour}
              onChange={(e) => setworkingHour(e.target.value)}
            />
            {validation.work_contract_weeks && (
              <div style={{ color: "red" }}>
                {validation.work_contract_weeks}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex">
          <div className="col-12">
            <label className="text-dark-ds pb-2">Status</label>
            <div className="option-modal-add">
              <Input.Group compact className="mb-3">
                <Select
                  // value={+teamValue}
                  placeholder="Status"
                  value={addStatusActive}
                  onChange={(value) => setAddStatusActive(value)}
                >
                  <Option value="0">Active</Option>

                  <Option value="1">Inactive</Option>
                </Select>
              </Input.Group>
              {/* {validation.team_id && (
            <div style={{ color: "red" }}>{validation.team_id}</div>
          )} */}
            </div>
          </div>
        </div>

        <label className="text-dark-ds pb-2">Email</label>
        <Input
          type="email"
          className="mb-3"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        {validation.email && (
          <div style={{ color: "red" }}>{validation.email}</div>
        )}
        <label className="text-dark-ds pb-2">Password</label>
        <Input
          className="mb-3"
          type="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
        />
        {validation?.password !=
          "The password confirmation does not match." && (
          <div style={{ color: "red" }}>{validation?.password}</div>
        )}
        <label className="text-dark-ds pb-2">Confirm Password</label>
        <Input
          className="mb-4"
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setpasswordConfirmation(e.target.value)}
        />
        {(errorpasswordConfirm && (
          <div style={{ color: "red" }}>
            The Password Confirmation field is required
          </div>
        )) ||
          (validation?.password != "The password field is required." && (
            <div style={{ color: "red" }}>{validation?.password}</div>
          ))}
        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5  "
            onClick={sumbitEmployee}
            disabled={disableSubmit}
          >
            Submit &nbsp;{" "}
            <Spin
              size="small"
              hidden={!disableSubmit}
              style={{ marginTop: "3px" }}
            />
          </Button>
          {/* <Button
            htmlType="submit"
            className="btn-aa px-4 mx-2"
            onClick={() => resetHandle()}
          >
            Reset
          </Button> */}
        </div>
      </Modal>

      {/* Modal Save Employee */}

      <Modal
        title={null}
        centered
        visible={modalSaveEmployee}
        width={400}
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <div className="col-12 pb-3 ">
          <b>Success!</b>
        </div>

        <label className="text-dark-ds pb-2 mb-3">
          Employee has been Added
        </label>

        <div className=" text-center">
          <Button
            htmlType="submit"
            className="btn-aa px-5 mx-2"
            onClick={() => closeSaveEmployee()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default TableEmployee;
