import React from 'react'
import TableCost from '../../components/table/TableCost'
import "./CostPerEntitites.css"

const CostPerEntities = () => {
  return (
    <div className="right-side-wrapper">
    <div className="customePaddingContainer">
    <div className="pt-2 title-table-ds">
        <p>
          <b>Cost Per Entities</b>
        </p>
      </div>
      <div className="card-table-ds">
        <TableCost/>
      </div>
    </div>
  </div>
  )
}

export default CostPerEntities