import React from 'react'
import TableProject from '../../components/table/TableProject'
import "./ProjectsPage.css"


const ProjectsPage = () => {
  return (
    <div className="right-side-wrapper">
    <div className="customePaddingContainer">
    <div className="pt-2 title-table-ds">
        <p>
          <b>Projects</b>
        </p>
      </div>
      <div className="card-table-ds">
        <TableProject/>
      </div>
    </div>
  </div>
  )
}

export default ProjectsPage