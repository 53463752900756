import React from 'react'
import TableViewAll from '../../components/table/TableViewAll'
import "./ViewAllPage.css"

const ViewAllPage = () => {
  return (
    <div className="right-side-wrapper">
      <div className="customePaddingContainer">
      <div className="pt-2 title-table-ds">
          <p>
            <b>vIEW ALL</b>
          </p>
        </div>
        <div className="card-table-ds">
          <TableViewAll/>
        </div>
      </div>
    </div>
  )
}

export default ViewAllPage